import Vue from 'vue'
import VueRouter from 'vue-router'
import indexView from '../views/IndexView.vue'
import bktjS from '../views/bktjS.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: indexView,
    meta:{
      title:'首页'
    }
  },
  {
    path: '/exam_home',
    name: 'exam_home',
    component: bktjS,
    meta:{
      title:'报考军校'
    }
  },
  {
    path: '/civil_home',
    name: 'civil_home',
    component: (resolve) =>
      require(["@/views/jdwz.vue"], resolve),
    meta:{
      title:'军队文职'
    }
  },
  {
    path: '/pub_home',
    name: 'pub_home',
    component: (resolve) =>
      require(["@/views/pub.vue"], resolve),
    meta:{
      title:'考公考编'
    }
  },
  {
    path: '/edu_home',
    name: 'edu_home',
    component: (resolve) =>
      require(["@/views/xltS.vue"], resolve),
    meta:{
      title:'学历提升'
    }
  },
  {
    path: '/nat_home',
    name: 'nat_home',
    component: (resolve) =>
      require(["@/views/gfjy.vue"], resolve),
    meta:{
      title:'国防教育'
    }
  },
  {
    path: '/job_home',
    name: 'job_home',
    component: (resolve) =>
      require(["@/views/gdjy.vue"], resolve),
    meta:{
      title:'高端就业'
    }
  },
  {
    path: '/ssjg',
    name: 'ssjg',
    component: (resolve) =>
      require(["@/views/ssjg.vue"], resolve),
    meta:{
      title:'搜索结果'
    }
  },
  {
    path: '/lxkf',
    name: 'lxkf',
    component: (resolve) =>
      require(["@/views/lxkf.vue"], resolve),
    meta:{
      title:'联系客服'
    }
  },
  {
    path: '/xwlb',
    name: 'xwlb',
    component: (resolve) =>
      require(["@/views/xwlb.vue"], resolve),
    meta:{
      title:'新闻列表'
    }
  },
  {
    path: '/lxwm',
    name: 'lxwm',
    component: (resolve) =>
      require(["@/views/lxwm.vue"], resolve),
    meta:{
      title:'联系我们'
    }
  },
  {
    path: '/fgsxq',
    name: 'fgsxq',
    component: (resolve) =>
      require(["@/views/fgsxq.vue"], resolve),
    meta:{
      title:'分公司'
    }
  },
  {
    path: '/gglb',
    name: 'gglb',
    component: (resolve) =>
      require(["@/views/gglb.vue"], resolve),
    meta:{
      title:'公告列表'
    }
  },
  {
    path: '/xwxq',
    name: 'xwxq',
    component: (resolve) =>
      require(["@/views/xwxq.vue"], resolve),
    meta:{
      title:'新闻详情'
    }
  },
  {
    path: '/jcjf',
    name: 'jcjf',
    component: (resolve) =>
      require(["@/views/jcjf.vue"], resolve),
    meta:{
      title:'教材教辅'
    }
  },
  {
    path: '/news_list',
    name: 'news_list',
    component: (resolve) =>
      require(["@/views/jcjfB.vue"], resolve),
    meta:{
      title:'新闻列表'
    }
  },
  {
    path: '/skill_home',
    name: 'skill_home',
    component: (resolve) =>
      require(["@/views/zyjn.vue"], resolve),
    meta:{
      title:'职业技能'
    }
  },
  {
    path: '/cityl',
    name: 'cityl',
    component: (resolve) =>
      require(["@/views/cityl.vue"], resolve),
    meta:{
      title:'查找81之家公司'
    }
  },
  {
    path: '/zwT',
    name: 'zwT',
    component: (resolve) =>
      require(["@/views/zwT.vue"], resolve),
    meta:{
      title:'正文'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace=function replace(location) {
    return originalReplace.call(this,location).catch(err => err)
};
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
 next()
})
export default router
