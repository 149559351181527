<template>
    <div class="t_dialog" v-if="isShow">
      <div class="dialog_content" :style="{width: width, height: height}">
        <div class="header_title" v-if="isShowHeader">
          {{ title }}
          <slot name="title" />
          <van-icon @click="closePopup" v-if="isCloseMask" name="cross" />
        </div>
        <div class="content">
          <slot />
        </div>
        <div
          class="footer"
          :class="{'footer-no-button': !showCancelButton||!showConfirmButton, 'footer-null': (!showCancelButton&&!showConfirmButton)}"
          v-if="(footer!==null)"
        >
          <slot name="footer" />
          <template v-if="!$slots.footer">
            <van-button @click="closePopup" v-if="showCancelButton">{{cancelButtonText}}</van-button>
            <van-button type="danger" @click="submit" v-if="showConfirmButton">{{confirmButtonText}}</van-button>
          </template>
        </div>
      </div>
      <div class="dialog-mask" v-if="isShowMask" @click="closeMask" @scroll.stop.prevent=""></div>
    </div>
  </template>
  <script>
  export default {
    name: 'TDialog',
    props: {
      // 是否显示弹窗
      visible: {
        type: Boolean,
        default: false
      },
      // 弹窗标题
      title: {
        type: String,
      },
      width: {
        type: String,
        default: '351px'
      },
      height: {
        type: String,
        default: 'auto'
      },
      // 取消按钮文字
      cancelButtonText: {
        type: String,
        default: '取消'
      },
      // 确定按钮文字
      confirmButtonText: {
        type: String,
        default: '确定'
      },
      // 是否显示取消按钮
      showCancelButton: {
        type: Boolean,
        default: true
      },
      // 是否显示确定按钮
      showConfirmButton: {
        type: Boolean,
        default: true
      },
      // 是否显示头部
      isShowHeader: {
        type: Boolean,
        default: true
      },
      // 是否显示遮罩层
      isShowMask: {
        type: Boolean,
        default: true
      },
      // 是否点击遮罩关闭弹窗
      isClickMaskClose: {
        type: Boolean,
        default: true
      },
      // 是否显示关闭按钮
      isCloseMask: {
        type: Boolean,
        default: true
      },
      // 是否显示底部操作按钮 :footer="null"
      footer: Object,
    },
    computed: {
      isShow: {
        get() {
          return this.visible
        },
        set(val) {
          this.$emit('update:visible', val)
        }
      }
    },
    methods: {
      closePopup() {
        this.isShow = false
        this.$emit('close')
      },
      closeMask() {
        if (!this.isClickMaskClose) return
        this.isShow = false
      },
      submit() {
        this.$emit('submit')
        // this.isShow = false
      }
    },
  
  }
  </script>
  <style lang="scss" scoped>
  .t_dialog {
    .dialog_content {
      position: fixed;
      top: 50%;
      left: 50%;
      -webkit-transform: translate3d(-50%, -50%, 0);
      transform: translate3d(-50%, -50%, 0);
      max-width: 100%;
      max-height: 100%;
      background-color: #fff;
      z-index: 3001;
      border-radius: 8px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
    }
    .header_title {
      height: 40px;
      padding: 0 20px;
      font-size: 16px;
      color: #101010;
      background: #fff;
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 8px 8px 0 0;
      .van-icon {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 16px;
        color: #999;
        &:hover {
          color: #333;
        }
      }
    }
    .content {
      padding: 15px;
      width: calc(100% - 30px);
      overflow-y: auto;
    }
    .footer {
      height: 40px;
      line-height: 40px;
      color: #101010;
      background: #fff;
      border-top: 1px solid #e5e5e5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      border-radius: 0 0 8px 8px;
      
      button,van-button {
        flex: 0 50%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        flex: 1;
        padding: 0;
        font-size: 16px;
        border: none;
        border-radius: 0 0 8px 0px;
        &:first-child {
          border-right: 1px solid #e5e5e5;
          border-radius: 0 0 0px 8px;
        }
      }
      .van-button--primary {
        color: #007aff;
        border: none;
        background-color: #fff;
      }
      .van-button--danger {
        color: #ff4444;
        border: none;
        background-color: #fff;
      }
      &.footer-no-button {
        button {
          flex: 0 auto;
          border: none;
          border-radius: 0 0 8px 8px;
          width: 100%;
        }
      }
      &.footer-null {
        display: none;
      }
    }
    .dialog-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.7;
      z-index: 3000;
    }
  }
  </style>