<template>
  <div class="container">
    <Top :nameArr="[]" />
    <div class="top-title" v-if="!isMobile">
      <el-menu
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#bd1d00"
        text-color="#fff"
        active-text-color=""
        :default-openeds="['']"
        :menu-trigger="'hover'"
      >
        <el-submenu index="2" popper-class="autoSub">
          <!-- :disabled="true" -->
          <template slot="title"
            ><span class="hasLine">全部分类</span>
          </template>
          <div class="menu-link">
            <div
              class="p-title-box"
              v-for="(titleItem, titleindex) in all_menu"
              :key="titleindex"
            >
              <div class="p-title">{{ titleItem.name }}</div>
              <div class="p-link-box">
                <p
                  v-for="(pItem, pIndex) in titleItem.son_list"
                  :key="pIndex"
                  @click="
                    routerLink('news_list', {
                      type: `${titleItem.sort}_${pIndex}`,
                      jump_url: pItem.jump_url,
                      jump_type: pItem.jump_type,
                    })
                  "
                >
                  {{ pItem.name }}
                </p>
              </div>
            </div>
          </div>
        </el-submenu>

        <el-menu-item
          index="1"
          v-for="(item, index) in top_menu"
          :key="index"
          @click="routerN(item.page, {}, { id: item.typeId })"
          >{{ item.name }}</el-menu-item
        >
      </el-menu>
    </div>
    <div class="swiper-box">
      <swiper-container
        class="mySwiper"
        :navigation="!isMobile ? true : false"
        :pagination="!isMobile ? true : false"
        autoplay-delay="2500"
        autoplay-disable-on-interaction="false"
        :preventClicksPropagation="true"
        v-if="bannerList.length > 0"
      >
        <!-- autoplay-delay="2500" autoplay-disable-on-interaction="false" -->
        <swiper-slide
          class="my-swiper-slide"
          v-for="(item, index) in bannerList"
          :key="index"
          ><img
            class="sss"
            v-lazy="item.pic"
            style="cursor: pointer"
            alt=""
            @click="
              routerN(
                '',
                { jump_type: item.jumpType, jump_url: item.jumpUrl },
                {}
              )
            "
            :key="index"
        /></swiper-slide>
      </swiper-container>
    </div>

    <div class="city" v-if="!isMobile">
      <div class="city-left">
        <p>
          {{ region_center.split("|")[0] }} <span class="circle"></span
          >{{ region_center.split("|")[1] }}
        </p>
        <p>{{ region_title }}</p>
      </div>
      <div class="citye-right">
        <p
          v-for="(item, index) in cityList"
          :key="index"
          @click="routerLink('fgsxq', { branchId: item.branchId })"
        >
          {{ item.branchName }}
        </p>
      </div>
    </div>

    <contentN :contentList="all_module" v-if="all_module.length > 0" />
    <div class="content contentNw">
      <div class="content-top">
        <div class="top-box-border">
          <div class="top-en">{{ company_introsConfig.company_en_title }}</div>
          <div class="top-cn">{{ company_introsConfig.company_title }}</div>
        </div>
      </div>

      <div class="tabItem">
        <div
          :class="{ active: activeTabs == index }"
          @click="changeTabs(index)"
          v-for="(item, index) in company_intros"
          :key="index"
        >
          {{ item.title }}
        </div>
      </div>

      <div class="tab-box">
        <!-- 左侧视频 -->
        <div class="video-box" v-if="!isMobile">
          <div class="video-turn turn-up">
            <i class="el-icon-arrow-up" @click="turnLeft"></i>
          </div>
          <div class="swiper-box">
            <swiper-container
              class="mySwiper1"
              :class="{ mySwiperN1: videosList.length < 3 }"
              :slides-per-view="videosList.length >= 3 ? 3 : 1"
              space-between="10"
              direction="vertical"
              :loop="true"
              :loopAdditionalSlides="2"
              @click="touchStart"
              v-if="videosList.length > 0"
            >
              <swiper-slide
                class="my-swiper-slide"
                v-for="(item, index) in videosList"
                :class="{ scale: videosList.length >= 3 }"
                :key="index"
              >
                <div class="">
                  <div class="videoFix">
                    <video
                      width="305"
                      :src="item.videoUrl"
                      height="176"
                      :controls="false"
                      :poster="item.videoPic"
                    >
                      您的浏览器不支持Video标签。
                    </video>
                    <span
                      @click.prevent.stop="
                        (e) =>
                          radioStart(
                            e,
                            'video' + index,
                            item.videoUrl,
                            item.videoName
                          )
                      "
                      v-if="videOldId != 'video' + index"
                      ><img
                        v-lazy="'https://pic.web.81zhijia.com/assets/radio.png'"
                        alt=""
                    /></span>
                  </div>

                  <p>{{ item.videoName }}</p>
                </div>
              </swiper-slide>
              <swiper-slide
                class="my-swiper-slide"
                v-for="(item, Tsindex) in videosList"
                :class="{ scale: videosList.length >= 3 }"
                :key="'ss' + Tsindex"
              >
                <div class="">
                  <div class="videoFix">
                    <video
                      width="305"
                      :src="item.videoUrl"
                      height="176"
                      :controls="false"
                      :poster="item.videoPic"
                    >
                      您的浏览器不支持Video标签。
                    </video>
                    <span
                      @click.prevent.stop="
                        (e) =>
                          radioStart(
                            e,
                            'video' + Tsindex,
                            item.videoUrl,
                            item.videoName
                          )
                      "
                      v-if="videOldId != 'video' + Tsindex"
                      ><img
                        v-lazy="'https://pic.web.81zhijia.com/assets/radio.png'"
                        alt=""
                    /></span>
                  </div>

                  <p>{{ item.videoName }}</p>
                </div>
              </swiper-slide>
            </swiper-container>
          </div>
          <div class="video-turn turn-down">
            <i class="el-icon-arrow-down" @click="turnRight"></i>
          </div>
        </div>

        <!-- 右侧简介 -->
        <div class="tab-content">
          <swiper-container class="mySwiperV" id="mySwiperV">
            <swiper-slide
              class="my-swiper-slide swiper-no-swiping"
              v-for="(item, index) in company_intros"
              :key="index"
            >
              <div class="tabItem-content">
                <img v-lazy="item?.pic" alt="" />
                <p>
                  {{ item?.str }}
                </p>
              </div>
            </swiper-slide>
          </swiper-container>
        </div>
      </div>
    </div>
    <el-dialog
      :title="dialogName"
      :visible.sync="dialogVisible"
      class="videoVi"
      width="604"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="bg" style="background: #a7a7a7">
        <i class="el-icon-close" @click="handleClose"></i>
        <video width="100%" :src="dialogUrl" :controls="true" :id="dialogId">
          您的浏览器不支持Video标签。
        </video>
      </div>
    </el-dialog>

    <div class="content content-bg-teacher">
      <div class="content-top">
        <div class="top-box-border">
          <div class="top-en">{{ teacher_module.enname }}</div>
          <div class="top-cn">
            {{ teacher_module.cn_name || "师资团队" }}
          </div>
        </div>
        <div class="top-title" v-if="!isMobile">
          {{ teacher_module.intro || "81之家文职教研团队，90%军队退役专家" }}
        </div>
      </div>

      <div class="flex-teacher">
        <div class="right-teacher">
          <div class="teacher-intro">
            <div class="swiper-box">
              <swiper-container
                class="mySwiper2"
                id="pxzj"
                :navigation="true"
                :pagination="!isMobile ? true : false"
                loop="true"
                v-if="photosList.length > 0"
              >
                <swiper-slide
                  class="my-swiper-slide"
                  v-for="(item, index) in photosList"
                  :key="index"
                  ><img v-lazy="item.photoUrl" alt="" />
                  <!-- <p>{{ item.photoName }}</p> -->
                </swiper-slide>
              </swiper-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content" v-if="!isMobile">
      <div class="content-top">
        <div class="top-box-border">
          <div class="top-en">{{ honor_Config.honor_en_title }}</div>
          <div class="top-cn">{{ honor_Config.honor_title }}</div>
        </div>
        <div class="top-title">{{ honor_Config.honor_intro }}</div>
      </div>
      <img class="honor" v-lazy="honor_pic" alt="荣誉" />
    </div>
    <footerN />
  </div>
</template>

<script>
import Top from "@/components/topTitle.vue";
import footerN from "@/components/footer.vue";
import contentN from "@/components/content2.vue";
// 引入Swiper的CSS
import "swiper/css";
import {
  getConfig,
  videosList,
  photosList,
  getanners,
  cityList,
} from "@/api/api";
export default {
  name: "IndexView",
  data() {
    return {
      photosList: [],
      cityList: [],
      dialogVisible: false,
      dialogUrl: "",
      dialogName: "",
      dialogId: "",
      bannerList: [],
      activeIndex: "1",
      all_module: [],
      activeTabs: "0",
      all_menu: [],
      top_menu: [],
      honor_pic: "",
      videosList: [],
      videOldId: "",
      region_title: "",
      region_center: "",
      company_intros: [],
      company_introsConfig: {},
      honor_Config: {},
      teacher_module: {},
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  created() {
    this.getConfigFncN(15);
    this.getConfigFnc(16);
    this.getViedoList();
    this.getbannersList();
    this.getCityList();
    this.getPhotosListZj();
  },
  mounted() {},
  components: {
    Top,
    footerN,
    contentN,
  },
  methods: {
    getPhotosListZj() {
      photosList({
        type: 2,
      }).then((res) => {
        this.photosList = res.data;
      });
    },
    handleClose() {
      let dom = document.getElementById(this.dialogId);
      dom.pause();
      this.dialogVisible = false;
    },
    routerLink(name, query) {
      this.$router.push({
        name: name,
        query: query,
      });
    },
    routerN(name, linkObj, query) {
      this.$store.dispatch("routerTO", { name, linkObj, query });
    },
    handleSelect(e) {
      console.log(e);
    },
    changeTabs(value) {
      this.activeTabs = value;
      const swiperEl = document.getElementById("mySwiperV");
      swiperEl.swiper.slideTo(value, 1000, false); //切换到第一个slide，速度为1秒
    },
    getConfigFnc(id) {
      getConfig({
        configId: id,
      }).then((res) => {
        this.top_menu = res.data.top_menu;
        for (var i in res.data.top_menu) {
          if (res.data.top_menu[i].name == "热点资讯") {
            res.data.top_menu[i].page = "xwlb";
            res.data.top_menu[i].typeId = 10;
            break;
          }
        }
        // this.all_menu = res.data.all_menu;
        // res.data.all_module[0].routerName = ""

        this.teacher_module = {
          enname: res.data.teacher_module.enname,
          cn_name: res.data.teacher_module.cn_name,
          intro: res.data.teacher_module.intro,
        };
        this.all_module = res.data.all_module;
        this.honor_pic = res.data.honor_pic;
        this.honor_Config = {
          honor_en_title: res.data.honor_en_title,
          honor_title: res.data.honor_title,
          honor_intro: res.data.honor_intro,
        };
        this.region_center = res.data.region_center;
        this.region_title = res.data.region_title;
        this.company_intros = res.data.company_intros;
        this.company_introsConfig = {
          company_en_title: res.data.company_en_title,
          company_title: res.data.company_title,
        };
      });
    },
    getCityList() {
      cityList().then((res) => {
        this.cityList = res.data;
      });
    },
    getConfigFncN(id) {
      getConfig({
        configId: id,
      }).then((res) => {
        this.all_menu = res.data.all_menu;
      });
    },
    getViedoList() {
      videosList({}).then((res) => {
        // this.videosList = [...res.data,...res.data];
        this.videosList = res.data;
      });
    },
    turnLeft() {
      const swiperEl = document.getElementsByClassName("mySwiper1")[0];
      swiperEl.swiper.slidePrev();
      // if (this.videOldId) {
      //   let oldDom = document.getElementById(this.videOldId);
      //   oldDom.pause();
      //   this.videOldId = "";
      // }
    },
    turnRight() {
      const swiperEl = document.getElementsByClassName("mySwiper1")[0];
      swiperEl.swiper.slideNext();
      // if (this.videOldId) {
      //   let oldDom = document.getElementById(this.videOldId);
      //   oldDom.pause();
      //   this.videOldId = "";
      // }
    },
    getbannersList() {
      getanners({
        typeId: 1,
      }).then((res) => {
        this.bannerList = res.data;
      });
    },
    radioStart(e, id, url, name) {
      this.dialogUrl = url;
      this.dialogName = name;
      this.dialogId = id;
      this.dialogVisible = true;
      this.$nextTick(() => {
        let dom = document.getElementById(this.dialogId);
        dom.play();
      });
      // let dom = document.getElementById(id);
      // let oldDom = "";

      // if (this.videOldId) {
      //   oldDom = document.getElementById(this.videOldId);
      // }
      // if (id != this.videOldId) {
      //   this.videOldId = id;
      //   if (oldDom) {
      //     oldDom.pause();
      //   }
      //   dom.play();
      // }
    },
    touchStart(e) {
      // if (this.videOldId) {
      //   let oldDom = document.getElementById(this.videOldId);
      //   oldDom.pause();
      //   this.videOldId = "";
      // }
    },
  },
};
</script>
<style lang="scss">
.autoSub {
  // display: block !important;
}
.autoSub .el-menu--popup {
  margin-top: 0;
  border-radius: 0;
  box-shadow: none;
  opacity: 0.9;
}
.menu-link {
  padding: 13px 16px 45px;
  width: 294px;
  box-sizing: border-box;
  max-height: 515px;

  .p-title-box {
    margin-top: 27px;
    &:nth-of-type(1) {
      margin-top: 0;
    }
    .p-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      line-height: 22px;
      text-align: left;
    }
    .p-link-box {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      font-family: PingFangSC, PingFang SC;
      // font-weight: 600;
      font-size: 14px;
      color: rgba(255, 2558, 255, 0.7);
      line-height: 26px;

      p {
        margin: 0;
        margin-right: 8px;
        cursor: pointer;

        border-bottom: 1px solid transparent;
        &:hover {
          border-bottom: 1px solid #fff;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  text-align: left;
}
::v-deep .videoVi {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    position: relative;
    .el-icon-close {
      position: absolute;
      top: -40px;
      right: -40px;
      font-size: 40px;
      color: #bebebe;
      cursor: pointer;
    }
    video {
      display: block;
    }
  }
}
@media screen and (max-width: 768px) {
  .content {
    margin-top: 0.28rem;
    &.content-bg {
      background: none;
      background-size: 100%;
      padding: 0;
      margin-top: 0.4rem;
      margin-bottom: 0.3rem;
    }
    &.contentNw {
      .tab-box {
        padding: 0.4rem;
      }
      ::v-deep .tabItem {
        margin: 0.2rem auto 0.2rem;
        div {
          font-size: 0.2rem;
          color: #19191b;
          line-height: 0.5rem;
          text-align: center;
          border-radius: 0.17rem;
        }
      }
      ::v-deep .tab-content {
        width: 100%;

        .tabItem-content {
          margin-left: 0 !important;
          img {
            width: 100% !important;
            height: auto !important;
          }
          P {
            margin-top: 0.2rem;
            font-size: 0.16rem;
            color: #f6f6ff;
            line-height: 0.28rem;
            text-overflow: ellipsis;
            display: -webkit-box;
            //-webkit-box-orient: vertical;
            //-webkit-line-clamp: 11;
            //height: 5.3rem;
            overflow: hidden;
            text-align: left;
          }
        }
      }
    }

    ::v-deep .flex-teacher {
      padding: 0;
      margin-top: 0.38rem;
      // background: url("../assets/teacher_bg.png") no-repeat;
      background-size: 100%;
      display: flex;
      align-items: flex-end;

      .left-teacher {
        img {
          width: 100%;
        }
      }
      .right-teacher {
        // margin-left: 10px;
        width: 100%;
        .teacher-intro {
          // padding-top: 36px;
          text-align: left;
          .swiper-box {
            margin-top: 0;
          }
          .intro-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 36px;
            color: #ffffff;
            line-height: 50px;
          }
          .intro-name {
            margin-top: 10px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 24px;
            color: #ffffff;
            line-height: 42px;
            .name {
              font-size: 30px;
              color: #ff9300;
              margin-right: 10px;
            }
          }
          .intro-p {
            margin-top: 32px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: #ffffff;
            line-height: 28px;
            width: 610px;
          }
        }
      }

      .iconImge {
        position: relative;
        top: -30px;
      }
      .swiper-box {
        margin-top: 60px;
        // margin-bottom: 10px;
        width: 100%;
      }
      .swiper-box .mySwiper2 {
        width: 100%;
        &::part(bullet-active) {
          background-color: white;
        }

        &::part(button-next) {
          width: 0.4rem;
          height: 0.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba($color: #000000, $alpha: 0.2);
          border-radius: 50%;
          padding: 0.06rem;
          right: 0.1rem;
          --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
          cursor: none;
        }

        &::part(button-prev) {
          width: 0.4rem;
          height: 0.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba($color: #000000, $alpha: 0.2);
          border-radius: 50%;
          padding: 0.06rem;
          left: 0.1rem;
          --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
          cursor: none;
        }



        
        
        img {
          width: 100%;
          margin: 0 auto;
          display: block;
        }
        p {
          text-align: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 20px;
          color: #ffffff;
          line-height: 28px;
        }
      }
    }
    .honor {
      margin-top: 10px;
      display: block;
      width: 100%;
      height: 591px;
      background: #999;
    }
    .tabItem {
      display: flex;
      margin: 45px auto 45px;
      align-items: center;
      justify-content: center;
      div {
        margin-left: 20px;
        width: 164px;
        cursor: pointer;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #19191b;
        line-height: 50px;
        text-align: center;
        // color: white;
        border-radius: 17px;
        border: 1px solid #999;
        &.active {
          background: #bd1d00;
          color: white;
        }
      }
    }
    .tab-content-img {
      display: flex;
      justify-content: center;
      img {
        display: block;
        width: 1082px;
        height: 597px;
      }
    }

    .tab-box {
      display: flex;
      align-items: center;
      background: #bd1d00;
      padding: 40px 12%;
      .tab-content {
        .tabItem-content {
          margin-left: 80px;
          img {
            display: block;
            width: 714px;
            height: 394px;
            margin: auto;
          }
          p {
            margin-top: 40px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #f6f6ff;
            line-height: 28px;
            text-align: justify;
          }
        }
      }
      .video-box {
        // padding: 20px 0;
        .video-turn {
          width: 50px;
          height: 30px;
          margin: auto;
          background: rgba($color: #000000, $alpha: 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          &.turn-up {
            margin-bottom: 20px;
          }
          &.turn-down {
            margin-top: 20px;
          }
          .el-icon-arrow-up {
            font-size: 26px;
            color: white;
          }
          .el-icon-arrow-down {
            font-size: 26px;

            color: white;
          }
        }
      }
      .mySwiper1 {
        width: 300px;
        height: 525px;
        &::part(bullet-active) {
          background-color: white;
        }
        &::part(button-next) {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba($color: #000000, $alpha: 0.2);
          border-radius: 50%;
          padding: 15px;
          bottom: 27px;
          left: 113px;
          top: auto;
          transform: rotate(90deg);
          --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
          cursor: none;
        }
        &::part(button-prev) {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba($color: #000000, $alpha: 0.2);
          border-radius: 50%;
          padding: 15px;
          left: 113px;
          top: 0;
          transform: rotate(90deg);
          --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
          cursor: none;
        }
        .my-swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;
          /* Center slide text vertically */
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease-in-out;
          &.swiper-slide-prev {
            transform: scale(0.8);
          }
          &.swiper-slide-next {
            transform: scale(0.8);
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .content-top {
      margin: 0 auto;
      text-align: center;
      // padding-top: 74px;
      .top-box-border {
        // border: 1px dashed #;
        .top-en {
          font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
          font-weight: normal;
          font-size: 0.24rem;
          color: #c5c5c5;
          line-height: 1;
          text-align: center;
        }
        .top-cn {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 0.44rem;
          color: #141414;
          line-height: 1;
          letter-spacing: 0.04rem;
          margin-top: 0.1rem;
        }
      }

      .top-title {
        margin-top: 0.16rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 0.2rem;
        color: #858585;
        line-height: 0.28rem;
        letter-spacing: 1px;
        background: transparent;
      }
    }
    .content-btn {
      border-radius: 20px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 28px;
      color: #ffffff;
      line-height: 40px;
      letter-spacing: 2px;
      width: 335px;
      height: 80px;
      background: #bd1d00;
      line-height: 80px;
      margin: auto;
      margin-top: 30px;
      cursor: pointer;
    }
    .content-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10%;
      flex-wrap: wrap;
      margin-top: 40px;
      .flex-box {
        width: 23%;
        box-shadow: 0px 3px 0px 1px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        .pic-box {
          width: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .p-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 18px;
          color: #252525;
          line-height: 25px;
          text-align: center;
          margin: 16px auto 35px;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 60%;
          white-space: nowrap;
        }
        .btn {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #f2f2f2;
          text-align: center;
          width: 241px;
          height: 55px;
          margin: 23px auto 63px;
          line-height: 55px;
          border-radius: 6px;
          background: #bd1d00;

          cursor: pointer;
        }
        .text-box {
          padding: 18px 25px;
          .title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #39394d;
            line-height: 25px;
            text-align: center;
          }
          .destobr {
            font-family: PingFangSC, PingFang SC;
            font-weight: 300;
            font-size: 12px;
            color: #000000;
            line-height: 17px;
            text-align: center;
          }
        }
      }
    }
  }
  ::v-deep .swiper-box {
    .mySwiper {
      height: 100%;
      &::part(bullet-active) {
        background-color: white;
      }
      &::part(button-next) {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000000, $alpha: 0.2);
        border-radius: 50%;
        padding: 15px;
        right: 120px;
        --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
      }
      &::part(button-prev) {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000000, $alpha: 0.2);
        border-radius: 50%;
        padding: 15px;
        left: 120px;
        --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
      }
      .my-swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .container {
    .content {
      margin-top: 80px;

      ::v-deep .flex-teacher {
        padding: 0;
        // background: url("../assets/teacher_bg.png") no-repeat;
        background-size: 100%;
        display: flex;
        align-items: flex-end;
        width: 100%;
        .left-teacher {
          img {
            width: 385px;
          }
        }
        .right-teacher {
          // margin-left: 10px;
          width: 100%;
          .teacher-intro {
            // padding-top: 36px;
            text-align: left;
            width: 100%;
            .swiper-box {
              margin-top: 0;
            }
            .intro-title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 600;
              font-size: 36px;
              color: #ffffff;
              line-height: 50px;
            }
            .intro-name {
              margin-top: 10px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 24px;
              color: #ffffff;
              line-height: 42px;
              .name {
                font-size: 30px;
                color: #ff9300;
                margin-right: 10px;
              }
            }
            .intro-p {
              margin-top: 32px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 20px;
              color: #ffffff;
              line-height: 28px;
              width: 610px;
            }
          }
        }

        .iconImge {
          position: relative;
          top: -30px;
        }
        .swiper-box {
          margin-top: 60px;
          // margin-bottom: 10px;
          width: 100%;
        }
        .swiper-box .mySwiper2 {
          width: 100%;
          &::part(bullet-active) {
            background-color: white;
          }
          &::part(button-next) {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba($color: #000000, $alpha: 0.2);
            border-radius: 50%;
            padding: 15px;
            right: 120px;
            --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
          }
          &::part(button-prev) {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba($color: #000000, $alpha: 0.2);
            border-radius: 50%;
            padding: 15px;
            left: 120px;
            --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
          }
          img {
            width: 100%;
            margin: 0 auto;
            display: block;
          }
          p {
            text-align: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: #ffffff;
            line-height: 28px;
          }
        }
      }

      .honor {
        margin-top: 10px;
        display: block;
        width: 100%;
        height: auto;
        background: #999;
      }
      .tabItem {
        display: flex;
        margin: 0 auto;
        margin-top: 45px;
        margin-bottom: 45px;
        padding: 0 15%;
        justify-content: center;
        div {
          margin-left: 20px;
          width: 164px;
          cursor: pointer;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 20px;
          color: #19191b;
          line-height: 50px;
          text-align: center;
          // color: white;
          border-radius: 4px;
          border: 1px solid #999;
          &.active {
            background: #bd1d00;
            color: white;
          }
        }
      }
      .tab-box {
        display: flex;
        align-items: center;
        background: #bd1d00;
        padding: 40px 12%;
        .tab-content {
          width: calc(100% - 305px);
          .mySwiperV {
            width: 100%;
          }
          .tabItem-content {
            margin-left: 80px;
            img {
              display: block;
              width: 90%;
              margin: auto;
            }
            p {
              margin-top: 40px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #f6f6ff;
              line-height: 28px;
              text-align: justify;
            }
          }
        }
        .video-box {
          // padding: 20px 0;
          .video-turn {
            width: 50px;
            height: 30px;
            margin: auto;
            background: rgba($color: #000000, $alpha: 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &.turn-up {
              margin-bottom: 20px;
            }
            &.turn-down {
              margin-top: 20px;
            }
            .el-icon-arrow-up {
              font-size: 26px;
              color: white;
            }
            .el-icon-arrow-down {
              font-size: 26px;

              color: white;
            }
          }
        }
        .mySwiper1 {
          width: 305px;
          height: 684px;
          &.mySwiperN1 {
            height: 216px;
          }
          &::part(bullet-active) {
            background-color: white;
          }
          &::part(button-next) {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba($color: #000000, $alpha: 0.2);
            border-radius: 50%;
            padding: 15px;
            bottom: 27px;
            left: 113px;
            top: auto;
            transform: rotate(90deg);
            pointer-events: auto;
            --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
            //cursor: none;
          }
          &::part(button-prev) {
            pointer-events: auto;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba($color: #000000, $alpha: 0.2);
            border-radius: 50%;
            padding: 15px;
            left: 113px;
            top: 0;
            transform: rotate(90deg);
            --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
            //cursor: none;
          }
          ::slotted(swiper-slide) {
            height: auto;
          }
          .my-swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
            height: auto;
            /* Center slide text vertically */
            // display: flex;
            // justify-content: center;
            // align-items: center;

            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            overflow: hidden;
            transition: all 0.3s ease-in-out;
            transform: scale(0.9);
            &:hover {
              transform: scale(1);
            }
            // &.scale.swiper-slide-prev {
            //   transform: scale(0.8);
            // }
            // &.scale.swiper-slide-next {
            //   transform: scale(0.8);
            // }
            img {
              width: 100%;
              height: 100%;
            }
            .videoFix {
              display: block;
              position: relative;
              video {
                width: 100%;
                object-fit: fill;
              }
              span {
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 305px;
                height: 176px;
                background: rgba($color: #000000, $alpha: 0.2);
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                  width: 40px;
                  height: 40px;
                  display: block;
                }
              }
            }
            p {
              margin: 0;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #39394d;
              line-height: 38px;
              height: 40px;
              text-align: center;
            }
          }
        }
      }
      .content-top {
        margin: 0 auto;
        text-align: center;
        .top-box-border {
          // border: 1px dashed #;
          .top-en {
            font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
            font-weight: normal;
            font-size: 24px;
            color: #c5c5c5;
            line-height: 1;
            text-align: center;
          }
          .top-cn {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 44px;
            color: #141414;
            line-height: 1;
            letter-spacing: 4px;
            margin-top: 10px;
          }
        }

        .top-title {
          margin-top: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 20px;
          color: #858585;
          line-height: 28px;
          letter-spacing: 1px;
          background: transparent;
        }
      }
      .content-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10%;

        .flex-box {
          width: 23%;
          box-shadow: 0px 3px 0px 1px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          .pic-box {
            width: 100%;
            height: 146px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text-box {
            padding: 18px 25px;
            .title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 18px;
              color: #39394d;
              line-height: 25px;
              text-align: center;
            }
            .destobr {
              font-family: PingFangSC, PingFang SC;
              font-weight: 300;
              font-size: 12px;
              color: #000000;
              line-height: 17px;
              text-align: center;
            }
          }
        }
      }
    }
    ::v-deep .swiper-box {
      .mySwiper {
        height: 525px;
        &::part(bullet-active) {
          background-color: white;
        }
        &::part(button-next) {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba($color: #000000, $alpha: 0.2);
          border-radius: 50%;
          padding: 15px;
          right: 120px;
          pointer-events: auto;
          --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
          //cursor: none;
        }
        &::part(button-prev) {
          pointer-events: auto;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba($color: #000000, $alpha: 0.2);
          border-radius: 50%;
          padding: 15px;
          left: 120px;
          --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
          //cursor: none;
        }
        .my-swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;
          /* Center slide text vertically */
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .city {
      background: #bd1d00;
      padding: 40px 13%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
      .city-left {
        font-size: 28px;
        line-height: 60px;
        width: 230px;
        text-align: left;
        p {
          margin: 0;
          display: flex;
          align-items: center;
        }
        span {
          display: inline-block;
          width: 9px;
          height: 9px;
          background: #ffffff;
          margin: 0 10px;
          border-radius: 50%;
        }
      }
      .citye-right {
        flex: 1;
        margin-left: 15px;
        font-size: 20px;
        line-height: 60px;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        p {
          margin: 0 10px;
        }
      }
    }
    ::v-deep .top-title {
      background: #bd1d00;
      padding: 0 13%;
      height: 60px;

      .el-menu {
        display: flex;
        justify-content: space-between;
        border: none;
        .el-submenu__title {
          color: #ffffff;
          opacity: 1;
          cursor: auto;
          padding: 0 13px;
          background-color: rgb(189, 29, 0) !important;
          .hasLine {
            padding: 0 5px 4px;
            border-bottom: 2px solid #ffffff;
          }
          .el-submenu__icon-arrow {
            // display: none;
          }
        }
        .el-menu-item {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 18px;
          color: #ffffff;
          // line-height: 22px;
          text-align: left;
        }
        .el-submenu__title {
          font-weight: 600;
          font-size: 16px;
          &:nth-of-type(1) {
            flex: 1;
            text-align: left;
            font-weight: 600;
            font-size: 18px;
            width: 294px;
          }
          .el-icon-arrow-down {
            color: white;
          }
        }
      }
    }
    .search-box {
      padding: 0 15%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 20px 0 25px;
      img.icon {
        width: 147px;
        height: 54px;
      }
      .search-content {
        display: flex;
        line-height: 54px;
        font-size: 12px;
        border-radius: 4px;
        overflow: hidden;

        border: 1px solid rgba(74, 74, 74, 0.2);
        box-shadow: 0px 0px 8px 0px rgba(177, 177, 177, 0.5);
        input {
          padding: 0 20px;
          border: none;
          outline: none;
          width: 343px;
        }
        div {
          font-size: 14px;
          width: 120px;
          text-align: center;
          background: #bd1d00;
          color: white;
          min-width: 54px;
        }
      }
      img.tel {
        width: 238px;
        height: 51px;
      }
    }
  }
}
</style>