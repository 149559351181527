<template>
  <div class="mibile_bottom">
    <template v-for="(item,index) in bottomMenuList">
      <div class="bottomMenu"  :key="index" @click="routerLink(item.type,item.name)" v-if="item.type == 'link'">
        <img v-lazy="item.acitvePic" alt="" v-if="activeIndex == index">
        <img v-lazy="item.pic" alt="" v-else>
        <div :class="{active:activeIndex == index}">{{ item.title }}</div>
      </div>
      <a class="bottomMenu" :href="`tel:${phone}`" :key="index" v-else>
        <img v-lazy="item.acitvePic" alt="" v-if="activeIndex == index">
        <img v-lazy="item.pic" alt="" v-else>
        <div :class="{active:activeIndex == index}">{{ item.title }}</div>
      </a>
    </template>
  </div>
</template>
<script>

export default {
  name: "mobileBottom",
  props: {
    phone:''
  },
  data() {
    return {
      activeIndex:0,
      bottomMenuList:[
        {
          // pic:require("../assets/icon/mobile1.png"),
          // acitvePic:require("../assets/icon/mobile1-a.png"),
          pic:'https://pic.web.81zhijia.com/common/home_2x.png',
          acitvePic:'https://pic.web.81zhijia.com/common/homed_2x.png',
          title:"首页",
          type:"link",
          name:"index"
        },
        {
          // pic:require("../assets/icon/mobile2.png"),
          // acitvePic:require("../assets/icon/mobile2-a.png"),
          pic:'https://pic.web.81zhijia.com/common/enquire_2x.png',
          acitvePic:'https://pic.web.81zhijia.com/common/enquired_2x.png',
          title:"在线咨询",
          type:"link",
          name:"lxkf"
        },
        {
          // pic:require("../assets/icon/mobile3.png"),
          // acitvePic:require("../assets/icon/mobile3-a.png"),
          pic:'https://pic.web.81zhijia.com/common/link_2x.png',
          acitvePic:'https://pic.web.81zhijia.com/common/linked_2x.png',
          title:"联系我们",
          type:"tel"
        }
      ]
    };
  },
  created() {},
  watch:{
    '$route.name'(val){
      if(val == 'lxkf'){
        this.activeIndex = 1
      }else{
        this.activeIndex = 0
      }
    }
  },
  methods: {
    routerLink(type,name){
      if(type == "link"){
        this.$router.push({
          name:name
        })
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.mibile_bottom{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: .98rem;
  display: flex;
  align-items: center;
  z-index: 1000;
  background: white;
  border-top: 1px solid rgba(151,151,151,0.3);
  z-index: 999;
  .bottomMenu{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    img{
      display: block;
      width: .44rem;
      height: .44rem;
    }
    div{
      margin-top: 3px;
      font-family: PingFangHK, PingFangHK;
      font-weight: 400;
      font-size: .22rem;
      color: #666666;
      line-height: .3rem;
      &.active{
        color: #FF262D;
        text-align: center;
      }
    }
  }
}

</style>