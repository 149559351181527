<template>
  <div class="footer" v-if="!isMobile">
    
    <div class="bah-box">
      <div>{{ configList.bottom_line1 }}</div>
      <div>{{ configList.bottom_line2 }}</div>
      <div class="bah" @click="linkW('http://beian.miit.gov.cn/')">
        <img v-lazy="'https://pic.web.81zhijia.com/assets/beian.png'" alt="" />{{ configList.record_number }}
      </div>
    </div>
    <kftc
      :ewm="configList.link_qrcode"
      :centerDialogVisible="centerDialogVisible"
      @close="close"
      @show="show"
    />
  </div>
</template>
  <script>
import kftc from "./kftc.vue";
import { getConfig, getLinks } from "@/api/api";
export default {
  name: "footerNM",
  props: {},
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  components: {
    kftc,
  },
  data: () => {
    return {
      configList: {},
      centerDialogVisible: false,
      linksList: [],
    };
  },
  created() {
    this.getConfigFnc();
    this.getLinksFnc();
  },
  methods: {
    linkW(linkUrl) {
      window.open(linkUrl);
    },
    routerLink(name, query) {
      this.$router.push({
        name: name,
        query: query,
      });
    },
    routerN(name, linkObj, query) {
      this.$store.dispatch("routerTO", { name, linkObj, query });
    },
    close() {
      this.centerDialogVisible = false;
    },
    show() {
      this.centerDialogVisible = true;
    },
    getLinksFnc() {
      getLinks().then((res) => {
        this.linksList = res.data;
      });
    },
    getConfigFnc() {
      getConfig({
        configId: 2,
      }).then((res) => {
        this.configList = res.data;
        this.configList.module_list.forEach((item) => {
          if (item.name == "联系我们") {
            item.id = 2;
          } else if (item.name == "法律声明") {
            item.id = 22;
          } else if (item.name == "公司资质") {
            item.id = 21;
          } else if (item.name == "关于我们") {
            item.id = 20;
          }
        });
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
.footer {
  margin-top: 76px;
  padding: 20px 12% 36px;
  background: #bd1d00;
  color: white;
  .bah-box {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 2558, 255, 0.6);
    line-height: 22px;
    text-align: center;
    div {
      &:nth-of-type(1) {
        margin-top: 64px;
      }
      &:nth-of-type(2) {
        margin-top: 12px;
      }
      &:nth-of-type(3) {
        margin-top: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          display: block;
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  .about-us {
    display: flex;
    align-items: center;
    padding-top: 18px;
    .button {
      min-width: 120px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: rgba($color: #fefefc, $alpha: 0.6);
      line-height: 22px;
      text-align: left;
      width: 200px;
      margin:0 3%;
      p {
        &.title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: #fee6e6;
        }
        &:hover {
          color: #fee6e6;
          cursor: pointer;
        }
      }
    }
    .ewm-box {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .img-box {
        margin-top: 32px;
        
        width: 30%;
      }
      img {
        display: block;
        width: 127px;
        margin: auto;
        height: 125px;
        padding: 6px;
        background: rgba($color: #ffffff, $alpha: 0.68);
        border-radius: 4px;
      }
      p {
        margin: 0;
        margin-top: 6px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
      }
    }
    .call-me {
      margin-left: 6%;
      .imgBox {
        display: flex;
        align-items: center;
        img {
          height: 54px;
          width: auto;
          display: block;
          margin-top: 0;
        }
        .text {
          text-align: left;
          margin-left: 20px;
          span {
            display: block;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
            line-height: 22px;
            &.ptext {
              font-family: PingFangSC, PingFang SC;
              font-weight: 600;
              font-size: 22px;
              color: #ffffff;
              line-height: 30px;
              margin-top: 10px;
            }
          }
        }
      }
      .hImgBox {
        margin-top: 47px;
        p{
          margin: 0;
          text-align: left;
          margin-top: 8px;
          font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 16px;
color: #FFFFFF;
line-height: 22px;
        }
        .imgBox {
          display: flex;
          align-items: center;
          img {
            height: 54px;
            width: auto;
            display: block;
            margin-top: 0;
          }
          .text {
            text-align: left;
            margin-left: 20px;
            span {
              display: block;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #ffffff;
              line-height: 22px;
              &.ptext {
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 22px;
                color: #ffffff;
                line-height: 30px;
                margin-top: 10px;
              }
            }
          }
        }
      }
      .tel {
        width: 212px;
        height: 54px;
        display: block;
        margin-top: 45px;
      }
      .kf {
        width: 179px;
        height: 100px;
        display: block;
        margin-top: 54px;
      }
    }
  }
  .about-link {
    .a-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      line-height: 25px;
      padding-bottom: 11px;
      border-bottom: 1px solid #ffffff;
      text-align: left;
    }
    .a-link {
      display: flex;
      align-items: center;
      margin-top: 9px;
      p {
        margin: 0;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>