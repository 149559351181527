<template>
  <div id="app" :class="{ mobile: isMobile }">
    <div class="fixed_right" v-if="!isMobile">
      <template v-for="(item, index) in fixedList">
        <div
          :style="{
            background:
              'url(' +
              (hoverIndex == index + 1
                ? item.chose_icon
                : item.icon || item.icon) +
              ')',
          }"
          @click="
            item.name != '公告'
              ? show(item.qrcode,item.name)
              : routerN(
                  '',
                  { jump_type: item.jump_type, jump_url: item.jump_url },
                  {}
                )
          "
          @mouseenter="mouseenter($event, index + 1)"
          @mouseleave="mouseleave"
          :key="index"
        ></div>
        <!-- <div class="app"  @click="show()"></div>
        <div class="wx"></div>
        <div class="gg" @click="routerN('gglb')"></div> -->
      </template>
      <div class="kf" @click="routerN('lxkf')">
        <!-- <div :style="{ background: 'url(' + kf.icon + ')' }"></div> -->
        <img :src="kf.icon" alt="">
        <!-- <p>联系客服</p> -->
      </div>
    </div>
    <div class="fixed_right_m" v-else>
      <!-- <div class="app"></div> -->
      <template v-for="(item, index) in fixedList">
        <div
          class="app"
          :style="{
            background: 'url(' + item.chose_icon + ')',
            backgroundSize: '100% 100%',
          }"
          @click="
            item.name == 'App'
              ? routerC(item.jump_url) :  item.name == '微信'?show(item.qrcode,item.name)
              : routerN(
                  '',
                  { jump_type: item.jump_type, jump_url: item.jump_url },
                  {}
                )
          "
          @mouseenter="mouseenter($event, index + 1)"
          @mouseleave="mouseleave"
          :key="index"
        ></div>
      </template>
    </div>
    <mobileTop v-if="isMobile" />
    <router-view />
    <!-- <footerMobil v-if="isMobile"/> -->
    <MobileBottom :phone="phone" v-if="isMobile" />
    <TDialog :visible="showVisible"  :is-click-mask-close="false" :is-show-header="false" @submit="submit" @close="closeB">
      <div class="dwonLApp">即将跳转并下载81之家App</div>
    </TDialog>
    <kftc
      :center-dialog-visible="centerDialogVisible"
      :ewm="ewm"
      :btn-text1="btnText1"
      :btn-text2="btnText2"
      @close="close"
      @show="show"
    />
  </div>
</template>
<script>
import { getConfig } from "@/api/api.js";
import { autoSize } from "@/utils/auth.js";
import mobileTop from "@/components/mobileTop.vue";
import MobileBottom from "./components/mobileBottom.vue";
import kftc from "@/components/kftc.vue";
import footerMobil from "@/components/footerMobil.vue";
import TDialog from "@/components/dialog.vue";
// import { showConfirmDialog } from "vant";
export default {
  data: () => {
    return {
      isMobile: false,
      phone: "",
      centerDialogVisible: false,
      ewm: "",
      fixedList: [],
      hoverIndex: "",
      grayType: 0,
      kf: {},
      showVisible:false,
      url:'',
      btnText1:"微信扫码",
      btnText2:"领取资料"
    };
  },
  created() {
    this.$store.state.isMobile = window.innerWidth < 768 ? true : false;
    this.isMobile = window.innerWidth < 768 ? true : false;
    autoSize();
    window.addEventListener("resize", () => {
      autoSize();
    });
    this.getConfigFnc();
  },
  components: {
    mobileTop,
    MobileBottom,
    kftc,
    TDialog,
    footerMobil
  },
  watch:{
    showVisible(val){
      if(val){
        document.body.classList.add("overFlow");
      }else{
        document.body.classList.remove("overFlow");
      }
     
    }
  },
  methods: {
    submit(){
      this.showVisible = false
      window.location.href = this.url
      // window.open(this.url)
    },
    closeB(){
      this.showVisible = false
      
    },
    mouseenter(e, index) {
      this.hoverIndex = index;
    },
    mouseleave(index) {
      this.hoverIndex = "";
    },
    routerC(url) {
      this.url = url
      this.showVisible = true
    },
    close() {
      this.centerDialogVisible = false;
    },
    show(pic,name) {
      this.centerDialogVisible = true;
      if(name == 'App'){
        this.btnText1 = "手机扫码"
        this.btnText2 = "注册下载"
      }else{
         this.btnText1 = "微信扫码"
        this.btnText2 = "领取资料"
      }
      this.ewm = pic;
    },
    routerN(name, linkObj, query) {
      this.$store.dispatch("routerTO", { name, linkObj, query });
    },
    getConfigFnc() {
      getConfig({
        configId: 1,
      }).then((res) => {
        this.grayType = res.data.canvas;
        let element = document.querySelector("html");
        if (this.grayType != 1) {
          element.classList.add("gray-out");
        } else {
          element.classList.remove("gray-out");
        }
      });
      getConfig({
        configId: 2,
      }).then((res) => {
        this.phone = res.data.link_phone;
      });
      getConfig({
        configId: 3,
      }).then((res) => {
        this.kf = {};
        this.fixedList = res.data.filter((item) => {
          if (item.name == "客服") {
            this.kf = item;
          }
          return item.name != "客服";
        });
      });
    },
  },
};
</script>
<style  lang="scss">
.overFlow{
  overflow: hidden;
}
.el-table__header, .el-table__body, .el-table__footer { width:100% !important; table-layout: fixed !important; }
.el-table__fixed-right { height: 100% !important; }
.load-img{
  background: url("./assets/imgLoading.png") no-repeat center #eeeeee;
  background-size:auto 60%;
}
@media screen and (max-width: 768px) {
  .el-dialog__wrapper{
  z-index: 3001 !important;
}
.v-modal{
  z-index: 3000 !important;
}
  .el-message {
    padding: 0.2rem !important;
    min-width: 3.8rem !important;
    z-index: 3002 !important;
    .el-message__icon {
      font-size: 0.28rem;
      line-height: 0.28rem;
    }
    .el-message__content {
      font-size: 0.18rem;
      line-height: 0.28rem;
    }
  }
}
</style>
<style lang="scss" scoped>
.mobile {
  padding: 0.96rem 0.4rem;
  padding-top: calc(0.98rem + constant(safe-area-inset-top)); /* iOS */
  padding-top: calc(0.98rem + env(safe-area-inset-top)); /* Android */
  padding-bottom: calc(0.98rem + constant(safe-area-inset-bottom)); /* iOS */
  padding-bottom: calc(0.98rem + env(safe-area-inset-bottom)); /* Android */
  ::v-deep .container {
    min-height: calc(100vh - 1.96rem);
    position: relative;
    padding-bottom: 1.7rem;
    padding-top: 0.4rem;
    box-sizing: border-box;
  }
  .dwonLApp{
    font-size: .34rem;
    line-height: 1rem;
  }
}

.fixed_right_m {
  position: fixed;
  z-index: 1001;
  right: 0.2rem;
  top: calc(50% - 0.7rem);
  z-index: 3000;
  div {
    width: 0.7rem;
    height: 0.7rem;
    margin-top: 0.2rem;
  }
  .app {
    background: url("./assets/icon/app_r.png");
    background-size: 100% 100%;
  }
}

@keyframes emit {
  0% {
    transform: scale(0.8);
  }
  100% {
    // border-width: 1px;
    // opacity: 0;
    transform: scale(1.2);
  }
}
.fixed_right {
  position: fixed;
  z-index: 1001;
  right: 20px;
  top: calc(50% - 160px);
  div {
    width: 69px;
    height: 69px;
    margin-top: 20px;
    cursor: pointer;
  }
  .kf {
    width: auto;
    height: auto;
    margin-top: 120px;
    &:hover {
      p {
        color: #bd1d00;
      }
    }
    div {
      background-size: 100% 100%;
      line-height: 69px;
      text-align: center;
      // border-radius: 50%;
      position: relative;
      cursor: pointer;
    }
    p {
      font-weight: 600;
      font-size: 18px;
      margin: 5px 0;
    }
  }
  .app {
    background: url("./assets/icon/app.png");
    background-size: 100% 100%;
    &:hover {
      background: url("./assets/icon/app_r.png");
      background-size: 100% 100%;
    }
  }
  .wx {
    background: url("./assets/icon/wx.png");
    background-size: 100% 100%;
    &:hover {
      background: url("./assets/icon/wx_r.png");
      background-size: 100% 100%;
    }
  }
  .gg {
    background: url("./assets/icon/gg.png");
    background-size: 100% 100%;
    &:hover {
      background: url("./assets/icon/gg_r.png");
      background-size: 100% 100%;
    }
  }
}
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
