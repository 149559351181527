<template>
   <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      :before-close="beforeClose"
      width="289px"
      center
    >
      <div class="">
        <div class="close-box">
          <img v-lazy="'https://pic.web.81zhijia.com/assets/close.png'" alt="" @click="close" />
        </div>
        <img v-lazy="'https://pic.web.81zhijia.com/assets/ewm.png'" alt="" v-if="!ewm" />
        <img v-lazy="ewm" alt="" v-else />
        <div class="btn-box">
          <div class="btn">{{btnText1}}</div>
          <div class="btn">{{btnText2}}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
</template>
<script>
export default {
  name: "dialogKf",
  props: {
    nameArr: {
      type: Array,
      default: () => {
        return ["首页"];
      },
      
    },
    centerDialogVisible:{
      type:Boolean,
      default:false
    },
    ewm:{
      type:String,
      default:""
    },
    btnText1:{
        type:String,
        default:()=>{
          return "微信扫码"
        }
      },
      btnText2:{
        type:String,
        default:()=>{
          return "领取资料"
        }
      }
  },
  data: () => {
    return {
      str:""
    };
  },
  created(){
    this.str = this.$route.query.str
  },
  methods:{
    close() {
      this.$emit("close")
    },
    show() {
      this.$emit("show")
    },
    beforeClose(){
      this.$emit("close")
    }
  }
};
</script>
<style lang="scss" scoped>

::v-deep .el-dialog {
  background: #ffffff;
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid rgba(151, 151, 151, 0.3);
  z-index: 3000 !important;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__footer {
    display: none;
  }
  .el-dialog__body {
    padding: 8px 22px 22px;
  }
  .close-box {
    display: flex;
    justify-content: flex-end;
    img {
      width: 16px;
      height: 16px;
      display: block;
      position: relative;
      left: 10px;
      cursor: pointer;
    }
  }
  img {
    width: 245px;
    height: 245px;
    display: block;
  }
  .btn-box {
    display: flex;
    .btn {
      flex: 1;
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 22px;
      color: #040101;
      line-height: 30px;
      cursor: pointer;
    }
  }
}
.mbx {
  background: #bd1d00;
  padding: 0 15%;
  text-align: left;
  span {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    line-height: 60px;
    &:nth-of-type(1) {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      line-height: 60px;
    }
  }
}
.search-box {
  padding: 0 15%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0 25px;
  img.icon {
    width: 147px;
    height: 54px;
  }
  .search-content {
    display: flex;
    line-height: 54px;
    font-size: 12px;
    border-radius: 4px;
    overflow: hidden;

    border: 1px solid rgba(74, 74, 74, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(177, 177, 177, 0.5);
    input {
      padding: 0 20px;
      border: none;
      outline: none;
      width: 343px;
    }
    div {
      font-size: 14px;
      width: 120px;
      text-align: center;
      background: #bd1d00;
      color: white;
      min-width: 54px;
      cursor: pointer;
    }
  }
  img.tel {
    width: 238px;
    height: 51px;
  }
}
</style>