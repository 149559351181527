var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ mobile: _vm.isMobile },attrs:{"id":"app"}},[(!_vm.isMobile)?_c('div',{staticClass:"fixed_right"},[_vm._l((_vm.fixedList),function(item,index){return [_c('div',{key:index,style:({
          background:
            'url(' +
            (_vm.hoverIndex == index + 1
              ? item.chose_icon
              : item.icon || item.icon) +
            ')',
        }),on:{"click":function($event){item.name != '公告'
            ? _vm.show(item.qrcode,item.name)
            : _vm.routerN(
                '',
                { jump_type: item.jump_type, jump_url: item.jump_url },
                {}
              )},"mouseenter":function($event){return _vm.mouseenter($event, index + 1)},"mouseleave":_vm.mouseleave}})]}),_c('div',{staticClass:"kf",on:{"click":function($event){return _vm.routerN('lxkf')}}},[_c('img',{attrs:{"src":_vm.kf.icon,"alt":""}})])],2):_c('div',{staticClass:"fixed_right_m"},[_vm._l((_vm.fixedList),function(item,index){return [_c('div',{key:index,staticClass:"app",style:({
          background: 'url(' + item.chose_icon + ')',
          backgroundSize: '100% 100%',
        }),on:{"click":function($event){item.name == 'App'
            ? _vm.routerC(item.jump_url) :  item.name == '微信'?_vm.show(item.qrcode,item.name)
            : _vm.routerN(
                '',
                { jump_type: item.jump_type, jump_url: item.jump_url },
                {}
              )},"mouseenter":function($event){return _vm.mouseenter($event, index + 1)},"mouseleave":_vm.mouseleave}})]})],2),(_vm.isMobile)?_c('mobileTop'):_vm._e(),_c('router-view'),(_vm.isMobile)?_c('MobileBottom',{attrs:{"phone":_vm.phone}}):_vm._e(),_c('TDialog',{attrs:{"visible":_vm.showVisible,"is-click-mask-close":false,"is-show-header":false},on:{"submit":_vm.submit,"close":_vm.closeB}},[_c('div',{staticClass:"dwonLApp"},[_vm._v("即将跳转并下载81之家App")])]),_c('kftc',{attrs:{"center-dialog-visible":_vm.centerDialogVisible,"ewm":_vm.ewm,"btn-text1":_vm.btnText1,"btn-text2":_vm.btnText2},on:{"close":_vm.close,"show":_vm.show}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }