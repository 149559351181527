<template>
  <div class="top-box" v-if="!isMobile">
    <div class="search-box">
      <div class="imgBox" @click="routerA('index')">
        <img v-lazy="dataStr.logo" class="icon" alt="icon" />
        <span>{{ dataStr.title }}</span>
      </div>
      <div class="search-content">
        <input
          type="text"
          v-model="str"
          :placeholder="dataStr.search"
          class="serch-input"
        />
        <div @click="getSerchFnc">搜索</div>
      </div>
      <div class="imgBox telBox">
        <img v-lazy="'https://pic.web.81zhijia.com/assets/telO.png'" class="tel" alt="icon" />
        <span>{{ dataStr.phone }}</span>
      </div>
    </div>
    <div class="mbx" v-if="this.nameArr.length > 0">
      <template v-for="(item, index) in nameArr">
        <span v-if="index == nameArr.length - 1" :key="index">{{ item }}</span>
        <span v-else :key="index" @click="routerB(item)"
          >{{ item }}<i class="el-icon-arrow-right"></i>
        </span>
      </template>
    </div>
  </div>
</template>
<script>
import { getConfig } from "@/api/api";
export default {
  name: "topTitle",
  props: {
    nameArr: {
      type: Array,
      default: () => {
        return ["首页"];
      },
    },
    typeA:"",
    getSerch: {
      type: Function,
      default: null,
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data: () => {
    return {
      str: "",
      dataStr: {},
      timeOutL: false,
      clickCount: 0,
    };
  },
  created() {
    this.str = this.$route.query.str;
    this.getConfigFnc();
  },
  methods: {
    routerA(name) {
      return false
      this.$router.push({
        name: name,
      });
    },
    getSerchFnc() {
      if (!this.str || this.str.length < 2) {
        this.$message({
          type: "error",
          message: "请输入不少于2字的关键词",
          duration: 2000,
        });
        return false;
      }
      if (!this.timeOutL) {
        this.timeOutL = true;
        setTimeout(() => {
          this.timeOutL = false;
          this.clickCount = 0;
        }, 5000);
      } else {
        this.clickCount++;
        if (this.clickCount == 10) {
          this.clickCount = 0;
          this.$message({
            type: "error",
            message: "您的搜索频率过高，请稍后重试",
            duration: 2000,
          });
        }
        return false;
      }
      if (this.getSerch) {
        this.getSerch(this.str);
        this.$router.replace({
          name: "ssjg",
          query: {
            str: this.str,
            type:this.typeA
          },
        });
      } else {
        this.$router.push({
          name: "ssjg",
          query: {
            str: this.str,
          },
        });
      }
    },
    routerB(name) {
      let routerlist = this.$router.getRoutes();
      for (var i in routerlist) {
        if (routerlist[i].meta.title == name) {
          this.$router.push({
            name: routerlist[i].name,
          });
          break;
        }
      }
    },
    getConfigFnc() {
      getConfig({
        configId: 1,
      }).then((res) => {
        this.dataStr = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mbx {
  background: #bd1d00;
  padding: 0 15%;
  text-align: left;
  display: flex;
  align-items: center;
  height: 60px;
  span {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    // line-height: 60px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .el-icon-arrow-right {
      font-weight: bold;
      margin: 0 10px;
      font-size: 16px;
      position: relative;
      top: 0.5px;
    }
    &:nth-of-type(1) {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      // line-height: 60px;
    }
  }
}

.search-box {
  padding: 0 15%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0 25px;
  img.icon {
    width: 147px;
    height: 54px;
  }
  .imgBox {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 145px;
    &.telBox {
      min-width: 240px;
      span {
        line-height: 54px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 26px;
        color: #d0021b;
        line-height: 37px;
        margin-left: 14px;
      }
    }
    img {
      width: auto;
      height: 54px;
      display: block;
    }
    span {
      line-height: 54px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #4a4a4a;
      line-height: 14px;
      margin-left: 14px;
    }
  }
  .search-content {
    display: flex;
    line-height: 54px;
    font-size: 12px;
    border-radius: 4px;
    overflow: hidden;

    border: 1px solid rgba(74, 74, 74, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(177, 177, 177, 0.5);

    input {
      padding: 0 20px;
      border: none;
      outline: none;
      width: 343px;
    }
    div {
      font-size: 14px;
      width: 120px;
      text-align: center;
      background: #bd1d00;
      color: white;
      min-width: 54px;
      cursor: pointer;
    }
  }
  img.tel {
    // width: 238px;
    height: 51px;
  }
}
</style>