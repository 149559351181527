<template>
  <div>
    <el-skeleton :loading="false" animated :count="4">
      <template slot="template">
        <el-skeleton-item variant="image" style="width: 240px; height: 240px" />
        <div style="padding: 14px">
          <el-skeleton-item variant="h3" />
          <div
            style="
              display: flex;
              align-items: center;
              justify-items: space-between;
              margin-top: 16px;
              height: 16px;
            "
          >
            <el-skeleton-item variant="text" />
          </div>
        </div>
      </template>
      <template>
        <div
          class="content"
          v-for="(content, contentIndex) in contentList"
          :key="contentIndex"
        >
          <div class=""></div>
          <div class="content-top" @click="linkeName(content.cn_title || content.cn_name)">
            <div class="top-box-border">
              <div class="top-en">{{ content.en_title || content.enname }}</div>
              <div
                class="top-cn"
              >
                {{ content.cn_title || content.title || content.cn_name }}
              </div>
            </div>

            <div class="top-title" v-if="!isMobile">{{ content.intro }}</div>
          </div>
          <div class="content-flex" v-if="!isMobile">
            <div
              class="flex-box"
              v-for="(flexContent, flexIndex) in content.pics"
              :class="{ 'col-3': content.pics.length > 4 }"
              :key="flexIndex"
            >
              <!-- 
          @click="routerN(content.routerName,{},{})" -->
          <!-- :style="{
                    width: !isMobile ? `${flexContent.picWidth}px` : '',
                    height: !isMobile ? `${flexContent.picHeight}px` : '',
                  }" -->
              <div class="pic-box">
                <img
                  v-lazy="flexContent.url"
                  alt=""
                  
                />
              </div>
              <div class="text-box" v-if="flexContent.title">
                <p class="title" :title="flexContent.title">
                  {{ flexContent.title }}
                </p>
                <p class="destobr" v-if="flexContent.intro" :title="flexContent.intro">
                  {{ flexContent.intro }}
                </p>
              </div>
              <div class="btn" v-if="flexContent.btnText" @click="show">
                {{ flexContent.btnText }}
              </div>
              <div class="p-title" v-if="flexContent.pTitle">
                {{ flexContent.pTitle }}
              </div>
            </div>
          </div>
          <div class="mobile_div" v-else>
            <swiper-container
              class="mySwiper"
              navigation="true"
              pagination="false"
            >
              <!--  space-between="20" -->
              <swiper-slide
                class="my-swiper-slide content-flex"
                v-for="(flexContent, flexIndex) in content.pics"
                :key="flexIndex"
              >
                <div
                  class="flex-box"
                  :style="{
                    width: !isMobile ? `${flexContent.picWidth}px` : '',
                    boxShadow:
                      flexContent.picWidth && !isMobile ? 'initial' : '',
                  }"
                  :class="{ 'col-3': content.pics.length > 4 }"
                  :key="flexIndex"
                >
                  <div class="pic-box">
                    <img
                      v-lazy="flexContent.url"
                      @click="linkeName(content.cn_title || content.cn_name)"
                      alt=""
                      :style="{
                        width: !isMobile ? `${flexContent.picWidth}px` : '',
                        height: !isMobile ? `${flexContent.picHeight}px` : '',
                      }"
                    />
                  </div>
                  <div class="text-box" v-if="flexContent.title">
                    <p class="title">{{ flexContent.title }}</p>
                    <p class="destobr" :title="flexContent.intro">
                      {{ flexContent.intro }}
                    </p>
                  </div>
                  <div class="btn" v-if="flexContent.btnText" @click="show">
                    {{ flexContent.btnText }}
                  </div>
                  <div class="p-title" v-if="flexContent.pTitle">
                    {{ flexContent.pTitle }}
                  </div>
                </div>
              </swiper-slide>
            </swiper-container>
          </div>
          <div
            class="content-btn"
            v-if="content.btnText && !isMobile"
            @click="show"
          >
            {{ content.btnText }}
          </div>
        </div>
      </template>
    </el-skeleton>
    <kftc
      :ewm="ewm"
      :centerDialogVisible="centerDialogVisible"
      @close="close"
      @show="show"
    />
  </div>
</template>
  <script>
import kftc from "@/components/kftc.vue";
export default {
  name: "contentBox",
  props: {
    contentList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    ewm: {
      type: String,
      default: "",
    },
  },
  components: {
    kftc,
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data: () => {
    return {
      activeTabs: 0,
      centerDialogVisible: false,
    };
  },
  methods: {
    close() {
      this.centerDialogVisible = false;
    },
    show() {
      this.centerDialogVisible = true;
    },
    routerN(name, linkObj, query) {
      this.$store._actions.routerTO(name, linkObj, query);
    },
    changeTabs(value) {
      this.activeTabs = value;
    },
    linkeName(name) {
      let urlName = "";
      if (name == "报考军校") {
        urlName = "exam_home";
      } else if (name == "军队文职") {
        urlName = "civil_home";
      } else if (name == "职业技能") {
        urlName = "skill_home";
      } else if (name == "学历提升") {
        urlName = "edu_home";
      } else if (name == "高端就业") {
        urlName = "job_home";
      } else if (name == "国防教育") {
        urlName = "nat_home";
      }
      if (urlName) {
        this.$router.push({
          name: urlName,
        });
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.content {
  cursor: pointer;
  margin-top: 80px;
  &.content-bg {
    background: url("../assets/bg.png") no-repeat;
    background-size: 100%;
  }
  .honor {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: 591px;
    background: #999;
  }
  .tabItem {
    display: flex;
    margin: 45px auto 45px;
    align-items: center;
    justify-content: center;
    div {
      margin-left: 20px;
      width: 164px;
      cursor: pointer;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #19191b;
      line-height: 50px;
      text-align: center;
      // color: white;
      border-radius: 17px;
      border: 1px solid #999;
      &.active {
        background: #bd1d00;
        color: white;
      }
    }
  }
  .tab-content-img {
    display: flex;
    justify-content: center;
    img {
      display: block;
      width: 1082px;
      height: 597px;
    }
  }
  .tab-box {
    display: flex;
    align-items: center;
    background: #bd1d00;
    padding: 40px 12%;
    .tab-content {
      .tabItem-content {
        margin-left: 80px;
        img {
          display: block;
          width: 714px;
          height: 394px;
          margin: auto;
        }
        p {
          margin-top: 40px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #f6f6ff;
          line-height: 28px;
          text-align: justify;
        }
      }
    }
    .video-box {
      // padding: 20px 0;
      .video-turn {
        width: 50px;
        height: 30px;
        margin: auto;
        background: rgba($color: #000000, $alpha: 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        &.turn-up {
          margin-bottom: 20px;
        }
        &.turn-down {
          margin-top: 20px;
        }
        .el-icon-arrow-up {
          font-size: 26px;
          color: white;
        }
        .el-icon-arrow-down {
          font-size: 26px;

          color: white;
        }
      }
    }
    .mySwiper1 {
      width: 300px;
      height: 525px;
      &::part(bullet-active) {
        background-color: white;
      }
      &::part(button-next) {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000000, $alpha: 0.2);
        border-radius: 50%;
        padding: 15px;
        bottom: 27px;
        left: 113px;
        top: auto;
        transform: rotate(90deg);
        --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
      }
      &::part(button-prev) {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000000, $alpha: 0.2);
        border-radius: 50%;
        padding: 15px;
        left: 113px;
        top: 0;
        transform: rotate(90deg);
        --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
      }
      .my-swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
        &.swiper-slide-prev {
          transform: scale(0.8);
        }
        &.swiper-slide-next {
          transform: scale(0.8);
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .content-top {
    margin: 0 auto;
    text-align: center;
    .top-box-border {
      // border: 1px dashed #;
      .top-en {
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 24px;
        color: #c5c5c5;
        line-height: 1;
        text-align: center;
      }
      .top-cn {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 44px;
        color: #141414;
        line-height: 1;
        letter-spacing: 4px;
        margin-top: 10px;
      }
    }

    .top-title {
      margin-top: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 20px;
      color: #858585;
      line-height: 28px;
      letter-spacing: 1px;
      background: transparent;
    }
  }
  .content-btn {
    border-radius: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 28px;
    color: #ffffff;
    line-height: 40px;
    letter-spacing: 2px;
    width: 335px;
    height: 80px;
    background: #bd1d00;
    line-height: 80px;
    margin: auto;
    margin-top: 30px;
    cursor: pointer;
  }
  .content-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10%;
    flex-wrap: wrap;
    margin-top: 40px;
    .flex-box {
      width: 23%;
      // box-shadow: 0px 3px 0px 1px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      border: 1px solid #e5e5e5;
      overflow: hidden;
      &.col-3 {
        width: 30%;
        margin-bottom: 20px;
        box-shadow: none;
      }
      .pic-box {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          margin: auto;
          display: block;
        }
      }
      .p-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #252525;
        line-height: 25px;
        text-align: center;
        margin: 16px auto 35px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        
      }
      .btn {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #f2f2f2;
        text-align: center;
        width: 241px;
        height: 35px;
        margin: 23px auto 43px;
        line-height: 35px;
        border-radius: 6px;
        background: #bd1d00;

        cursor: pointer;
      }
      .text-box {
        padding: 0px 25px 18px;
        .title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #39394d;
          line-height: 25px;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 16px auto;
          width: 100%;
          white-space: nowrap;
        }
        .destobr {
          font-family: PingFangSC, PingFang SC;
          font-weight: 300;
          font-size: 12px;
          color: #000000;
          line-height: 17px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          height: 34px;
          text-align: left;
        }
      }
    }
  }
  .mySwiper {
    &::part(bullet-active) {
      background-color: white;
    }
    &::part(button-next) {
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba($color: #000000, $alpha: 0.2);
      border-radius: 50%;
      padding: 6px;
      right: 120px;
      --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
    }
    &::part(button-prev) {
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba($color: #000000, $alpha: 0.2);
      border-radius: 50%;
      padding: 6px;
      left: 120px;
      --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
    }
    .my-swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
      &.content-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        // flex-wrap: wrap;
        // margin-top: 40px;
        .flex-box {
          width: 100%;
          box-shadow: 0px 3px 0px 1px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          &.col-3 {
            width: 30%;
            margin-bottom: 20px;
          }
          .pic-box {
            width: 100%;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
          .p-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 18px;
            color: #252525;
            line-height: 25px;
            text-align: center;
            margin: 16px auto 35px;
          }
          .btn {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #f2f2f2;
            text-align: center;
            width: 241px;
            height: 35px;
            margin: 23px auto 43px;
            line-height: 35px;
            border-radius: 6px;
            background: #bd1d00;

            cursor: pointer;
          }
          .text-box {
            padding: 18px 25px;
            .title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 18px;
              color: #39394d;
              line-height: 25px;
              text-align: center;
            }
            .destobr {
              font-family: PingFangSC, PingFang SC;
              font-weight: 300;
              font-size: 12px;
              color: #000000;
              line-height: 17px;
              text-align: left;
            }
          }
        }
      }
      // img {
      //   width: 100%;
      //   height: 100%;
      // }
    }
  }
}
@media screen and (max-width: 768px) {
  .content {
    margin-top: 0.28rem;
    border-radius: 0.05rem;
    border: 1px solid rgba(151, 151, 151, 0.3);
    padding: 0.42rem 0 0;
    
    padding-bottom: 1px;
    &.content-bg {
      background: url("../assets/bg.png") no-repeat;
      background-size: 100%;
    }
    .honor {
      margin-top: 10px;
      display: block;
      width: 100%;
      height: 591px;
      background: #999;
    }
    .tabItem {
      display: flex;
      margin: 45px auto 45px;
      align-items: center;
      justify-content: center;
      div {
        margin-left: 20px;
        width: 164px;
        cursor: pointer;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #19191b;
        line-height: 50px;
        text-align: center;
        // color: white;
        border-radius: 17px;
        border: 1px solid #999;
        &.active {
          background: #bd1d00;
          color: white;
        }
      }
    }
    .tab-content-img {
      display: flex;
      justify-content: center;
      img {
        display: block;
        width: 1082px;
        height: 597px;
      }
    }
    .tab-box {
      display: flex;
      align-items: center;
      background: #bd1d00;
      padding: 40px 12%;
      .tab-content {
        .tabItem-content {
          margin-left: 80px;
          img {
            display: block;
            width: 714px;
            height: 394px;
            margin: auto;
          }
          p {
            margin-top: 40px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #f6f6ff;
            line-height: 28px;
            text-align: justify;
          }
        }
      }
      .video-box {
        // padding: 20px 0;
        .video-turn {
          width: 50px;
          height: 30px;
          margin: auto;
          background: rgba($color: #000000, $alpha: 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          &.turn-up {
            margin-bottom: 20px;
          }
          &.turn-down {
            margin-top: 20px;
          }
          .el-icon-arrow-up {
            font-size: 26px;
            color: white;
          }
          .el-icon-arrow-down {
            font-size: 26px;

            color: white;
          }
        }
      }
      .mySwiper1 {
        width: 3rem;
        height: 5.25rem;
        &::part(bullet-active) {
          background-color: white;
        }
        &::part(button-next) {
          width: 0.3rem;
          height: 0.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba($color: #000000, $alpha: 0.2);
          border-radius: 50%;
          padding: 0.15rem;
          bottom: 0.27rem;
          left: 1.13rem;
          top: auto;
          transform: rotate(90deg);
          --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
          cursor: none;
        }
        &::part(button-prev) {
          width: 0.3rem;
          height: 0.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba($color: #000000, $alpha: 0.2);
          border-radius: 50%;
          padding: 0.15rem;
          left: 1.13rem;
          top: 0;
          transform: rotate(90deg);
          --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
          cursor: none;
        }
        .my-swiper-slide {
          text-align: center;
          font-size: 0.18rem;
          background: #fff;
          /* Center slide text vertically */
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease-in-out;
          &.swiper-slide-prev {
            transform: scale(0.8);
          }
          &.swiper-slide-next {
            transform: scale(0.8);
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .content-top {
      margin: 0 auto;
      text-align: center;
      .top-box-border {
        // border: 1px dashed #;
        .top-en {
          font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
          font-weight: normal;
          font-size: 0.24rem;
          color: #c5c5c5;
          line-height: 0.33rem;
          text-align: center;
        }
        .top-cn {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 0.44rem;
          color: #141414;
          line-height: 1;
          letter-spacing: 0.04rem;
          margin-top: 0.1rem;
        }
      }

      .top-title {
        margin-top: 0.16rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 0.2rem;
        color: #858585;
        line-height: 0.28rem;
        letter-spacing: 1px;
        background: transparent;
      }
    }
    .content-btn {
      border-radius: 0.2rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 0.28rem;
      color: #ffffff;
      line-height: 0.4rem;
      letter-spacing: 0.02rem;
      width: 335px;
      height: 80px;
      background: #bd1d00;
      line-height: 0.8rem;
      margin: auto;
      margin-top: 0.3rem;
      cursor: pointer;
    }
    .content-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10%;
      flex-wrap: wrap;
      margin-top: 0.4rem;
      .flex-box {
        width: 23%;
        box-shadow: none;
        border-radius: 5px;
        border:none;
        &.col-3 {
          width: 30%;
          margin-bottom: 0rem;
          box-shadow: none;
        }
        .pic-box {
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .p-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 0.18rem;
          color: #252525;
          line-height: 0.25rem;
          text-align: center;
          margin: 0.16rem auto 0.55rem;
        }
        .btn {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #f2f2f2;
          text-align: center;
          width: 2.41rem;
          height: 0.55rem;
          margin: 0.23rem auto 0.63rem;
          line-height: 0.55rem;
          border-radius: 0.06rem;
          background: #bd1d00;

          cursor: pointer;
        }
        .text-box {
          padding: 0.18rem 0.25rem;
          .title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 0.18rem;
            color: #39394d;
            line-height: 0.25rem;
            text-align: center;
          }
          .destobr {
            font-family: PingFangSC, PingFang SC;
            font-weight: 300;
            font-size: 0.12rem;
            color: #000000;
            line-height: 0.17rem;
            text-align: left;
          }
        }
      }
    }
    .mobile_div{
      padding-right: 1px;
    }
    .mySwiper {
      &::part(bullet-active) {
        background-color: white;
      }
      &::part(button-next) {
        width: 0.2rem;
        height: 0.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000000, $alpha: 0);
        border-radius: 0%;
        padding: 0.06rem;
        top: calc(50% - 20px);
        right: 0.2rem;
        --swiper-navigation-color: #a69d9d; /* 单独设置按钮颜色 */
        cursor: none;
      }
      &::part(button-prev) {
        width: 0.2rem;
        height: 0.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000000, $alpha: 0);
        border-radius: 0%;
        padding: 0.06rem;
        top: calc(50% - 20px);
        left: 0.2rem;
        --swiper-navigation-color: #a69d9d; /* 单独设置按钮颜色 */
        cursor: none;
      }
      .my-swiper-slide {
        text-align: center;
        font-size: 0.18rem;
        background: #fff;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
        &.content-flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          // flex-wrap: wrap;
          // margin-top: 40px;
          .flex-box {
            width: 100%;
            box-shadow: none;
            border-radius: 5px;

            padding: 0 0.6rem;
            &.col-3 {
              // width: 30%;
              width: 100%;
              margin-bottom: 0rem;
            }
            .pic-box {
              width: 100%;

              img {
                width: 100%;
              }
            }
            .p-title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 600;
              font-size: 0.18rem;
              color: #252525;
              line-height: 0.25rem;
              text-align: center;
              margin: 0.16rem auto 0.55rem;
            }
            .btn {
              font-family: PingFangSC, PingFang SC;
              font-weight: 600;
              font-size: 14px;
              color: #f2f2f2;
              text-align: center;
              width: 2.41rem;
              height: 0.55rem;
              margin: 0.23rem auto 0.63rem;
              line-height: 0.55rem;
              border-radius: 0.06rem;
              background: #bd1d00;
              padding: 0.06rem 0.12rem;

              cursor: pointer;
            }
            .text-box {
              padding: 0.18rem 0.25rem;
              .title {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 0.18rem;
                color: #39394d;
                line-height: 0.25rem;
                text-align: center;
              }
              .destobr {
                font-family: PingFangSC, PingFang SC;
                font-weight: 300;
                font-size: 0.12rem;
                color: #000000;
                line-height: 0.17rem;
                text-align: left;
              }
            }
          }
        }
        // img {
        //   width: 100%;
        //   height: 100%;
        // }
      }
    }
  }
}
</style>