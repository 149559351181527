import request from '@/utils/request'

// 查询配置 getConfig
export function getConfig(params) {
  return request({
    url: '/common/config/v1',
    method: 'get',
    params
  })
}
// 友情链接 
export function getLinks(params) {
  return request({
    url: '/common/links/v1',
    method: 'get',
    params
  })
}
// 轮播图片
export function getanners(params) {
  return request({
    url: '/common/banners/v1',
    method: 'get',
    params
  })
}
// 客服列表
export function getCustomers(params) {
  return request({
    url: '/common/customers/v1',
    method: 'get',
    params
  })
}
// 职业技能_课程列表
export function getCourses(params) {
  return request({
    url: '/common/courses/v1',
    method: 'get',
    params
  })
}

// 新闻列表 
export function getNewstypes(params) {
  return request({
    url: '/news/types/v1',
    method: 'get',
    params
  })
}
// 新闻列表 
export function getNewsList(params) {
  return request({
    url: '/news/list/v1',
    method: 'get',
    params
  })
}
// 最新新闻 
export function getNewsLatest(params) {
  return request({
    url: '/news/latest/v1',
    method: 'get',
    params
  })
}
// 搜索新闻 
export function getNewsSearch(params) {
  return request({
    url: '/news/search/v1',
    method: 'get',
    params
  })
}
// 新闻详情
export function getNewsInfo(params) {
  return request({
    url: '/news/info/v1',
    method: 'get',
    params
  })
}


// 公告分类
export function getNoticeType(params) {
  return request({
    url: '/notice/types/v1',
    method: 'get',
    params
  })
}
// 公告列表
export function getNoticeList(params) {
  return request({
    url: '/notice/list/v1',
    method: 'get',
    params
  })
}
// 搜索公告
export function getNoticeListSearch(params) {
  return request({
    url: '/notice/search/v1',
    method: 'get',
    params
  })
}
// 公告详情
export function getNoticInfo(params) {
  return request({
    url: '/notice/info/v1',
    method: 'get',
    params
  })
}
// 视频列表
export function videosList(params) {
  return request({
    url: '/media/videos/v1',
    method: 'get',
    params
  })
}
// 照片列表
export function photosList(params) {
  return request({
    url: '/media/photos/v1',
    method: 'get',
    params
  })
}
// 公司列表_首页使用
export function cityList(params) {
  return request({
    url: '/branch/list/v1',
    method: 'get',
    params
  })
}
// 公司详情
export function cityDetail(params) {
  return request({
    url: '/branch/info/v1',
    method: 'get',
    params
  })
}
// 公司列表_按大区分
export function cityDqList(params) {
  return request({
    url: '/branch/region/v1',
    method: 'get',
    params
  })
}


// 退出方法
export function logout(data) {
  return request({
    url: '/logout',
    method: 'post',
    data
  })
}
// 退出方法
export function getcityA(params) {
  return request({
    url: '/branch/letter/v1',
    method: 'get',
    params
  })
}
// 退出方法
export function saveSign(data) {
  return request({
    url: '/common/saveSign/v1',
    method: 'post',
    data
  })
}