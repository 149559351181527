<template>
  <div class="mibile_top">
    <div class="mibile_left">
      <img
        v-lazy="'https://pic.web.81zhijia.com/assets/icon/mobile.png'"
        class="logo"
        @click="routeLink('index')"
        alt="logo"
      />
      <div class="fb_box" @click="routeLink('cityl')">
        学习中心<i class="el-icon-arrow-down"></i>
      </div>
    </div>

    <div class="mobile_right">
      <i class="el-icon-search" @click="showSearchFnc"></i>
      <div class="line_box" @click="changeMenuShow">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
    <div class="menu-box" v-if="menuShow">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        :unique-opened="true"
      >
        <el-submenu :index="index+''" v-for="(item,index) in all_menu" :key="index">
          <template slot="title">
            <span>{{item.name}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item :index="`${index}_${pIndex}`" v-for="(pItem, pIndex) in item.son_list" :key="pIndex" @click="routerLinkN('news_list',{type:`${item.sort}_${pIndex}`,jump_url:pItem.jump_url,jump_type:pItem.jump_type})">{{pItem.name}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        
      </el-menu>
    </div>
    <div class="search-box" v-if="showSearch">
      <div class="search">
        <i class="el-icon-close" @click="hideSearchFnc"></i>
        <p>全站搜索</p>
        <el-input
          v-model="str"
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
        ></el-input>
        <div class="btn" @click="routeLink('ssjg', { str: str })">
          开始搜索
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getConfig,
} from "@/api/api";
export default {
  name: "mobileTop",
  props: {},
  data() {
    return {
      showSearch: false,
      str: "",
      all_menu:[],
      menuShow:false,
      timeOutL:false,
      clickCount:0
    };
  },
  created() {
    this.getConfigFnc()
  },
  methods: {
    changeMenuShow(){
        this.menuShow = !this.menuShow
    },
    routerLinkN(name, query) {
        this.menuShow = false
        if(this.$route,name == 'news_list'){
          this.$router.replace({
        name: name,
        query: query,
      });
        }else{
          this.$router.push({
        name: name,
        query: query,
      });
        }
      
    },
    routeLink(name, query) {
      if(name == "ssjg"){
        if (!this.str || this.str.length < 2) {
        this.$message({
          type: "error",
          message: "请输入不少于2字的关键词",
          duration: 2000,
        });
        return false;
      }
      if (!this.timeOutL) {
        this.timeOutL = true;
        setTimeout(() => {
          this.timeOutL = false;
          this.clickCount = 0;
        }, 5000);
      } else {
        this.clickCount++;
        if (this.clickCount == 10) {
          this.clickCount = 0;
          this.$message({
            type: "error",
            message: "您的搜索频率过高，请稍后重试",
            duration: 2000,
          });
        }
        return false;
      }
      }else if(this.$route.name == "cityl"){
        this.$router.go(-1)
        return false
      }
      this.showSearch = false;
      this.menuShow = false
      
      this.$router.push({
        name: name,
        query: query,
      });
    },
    showSearchFnc() {
      this.showSearch = true;
    },
    hideSearchFnc() {
      this.showSearch = false;
    },
    getConfigFnc(){
        getConfig({
            configId:15
        }).then(res=>{
            this.all_menu = res.data.all_menu
        })
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .menu-box{
    position: fixed;
  top: .96rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3001;
  overflow: auto;
  .el-submenu__title{
    text-align: left;
    
  }
  .el-menu-item-group__title{
      display: none;
    }
    .el-menu-item{
      text-align: left;
    }
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3001;
  .search {
    position: relative;
    z-index: 1;
    padding: 0 0.4rem;
    width: 80%;
    background: white;
    padding: 0.4rem 0.4rem;
    .el-icon-close {
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
      font-size: 0.24rem;
      padding: 0.08rem;
      display: block;
      background: #c3bcbc;
      color: white;
      z-index: 100;
      border-radius: 50%;
    }
    p {
      margin: 0;
      font-size: 0.32rem;
      margin-bottom: 0.3rem;
    }
    ::v-deep .el-input--medium {
      display: block;
    }
    .btn {
      font-size: 0.32rem;
      line-height: 0.7rem;
      background: #bd1d00;
      border-radius: 0.08rem;
      color: white;
      margin-top: 0.3rem;
    }
  }
}
.mibile_top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 3001;
  height: 0.96rem;
  padding: 0 0.5rem;
  background: #bd1d00;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .mibile_left {
    display: flex;
    align-items: center;
    .logo {
      width: 1.47rem;
      height: 0.54rem;
      display: block;
    }
    .fb_box {
      display: flex;
      align-items: center;
      height: 0.42rem;
      padding: 0 0.2rem;
      margin-left: 0.16rem;
      background: #ffffff;
      border-radius: 0.21rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 0.2rem;
      color: #000000;
      line-height: 0.28rem;
      .el-icon-arrow-down {
        font-size: 0.16rem;
        color: #000000;
        margin-left: .1rem;
      }
    }
  }
  .mobile_right {
    display: flex;
    .el-icon-search {
      font-size: 0.44rem;
      color: white;
      margin-right: 0.4rem;
    }
  }
  .line_box {
    height: 0.44rem;
    width: 0.44rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .line {
      height: 3px;
      background: #ffffff;
    }
  }
}
</style>