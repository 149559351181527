<template>
  <div class="container">
    <Top :nameArr="nameArr" />
    <img class="lbt" v-lazy="back_pic" alt="" v-if="!isMobile" />
    <div class="notic-box">
      <div class="notic-left">
        <div class="notic-new" v-if="!isMobile">最新资讯</div>
        <swiper-container
          class="mySwiper3"
          :navigation="!isMobile ? true : false"
          :pagination="!isMobile ? true : false"
          :loop="true"
          autoplay-delay="2500" autoplay-disable-on-interaction="false"
          v-if="bannerList.length > 0"
        >
          <swiper-slide
            class="my-swiper-slide"
            v-for="(item, index) in bannerList"
            :key="index"
            
            ><img v-lazy="item.pic" alt="" @click="routerN('',{jump_type:item.jumpType,jump_url:item.jumpUrl},{})"
          /></swiper-slide>
        </swiper-container>
        <img v-lazy="'https://pic.web.81zhijia.com/assets/notice.png'" alt="" v-else />
      </div>
      <div class="notic-right">
        <div class="notic-flex">
          <div class="notice-tabs">
            <p
              :class="{ 'notice-tabs-active': noticActive == index }"
              @click="changeNotice(index, notic.news_id)"
              v-for="(notic, index) in noticList"
              :key="index"
            >
              {{ notic.name }}
            </p>
            <!-- <p
              :class="{ 'notice-tabs-active': noticActive == 1 }"
              @click="changeNotice(1)"
            >
              优惠活动
            </p> -->
          </div>
          <div class="more" @click="routerN('xwlb', {}, { id: news_id })">
            更多 
            <!-- <i class="el-icon-d-arrow-right"></i> -->
          </div>
        </div>
        <div class="notice-content">
          <div
            class="notic-content-flex"
            v-for="(item, index) in newsList"
            :key="index"
            @click="routerN('zwT',{},{id:item.newsId,typeId:news_id})"
          >
            <div class="notic-text">
              <span class="dian"></span>
              <span class="text" :title="item.newsTitle">{{
                item.newsTitle
              }}</span>
            </div>
            <div class="notic-time">{{ item.createTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="content"
      v-for="(content, contentIndex) in contentList"
      :key="contentIndex"
    >
      <div class="content-top">
        <div class="top-box-border">
          <div class="top-en">{{ content.en_title }}</div>
          <div class="top-cn">{{ content.cn_title }}</div>
        </div>

        <div class="top-title">{{ content.intro }}</div>
      </div>
      <div class="content-flex">
        <div
          class="flex-box"
          v-for="(flexContent, flexIndex) in content.pics"
          :style="{
            width: `${flexContent.picWidth}px`,
            boxShadow: flexContent.picWidth ? 'initial' : '',
          }"
          :key="flexIndex"
        >
          <div class="pic-box">
            <img
              v-lazy="flexContent.url"
              alt=""
              :style="{
                width: `${flexContent.picWidth}px`,
                height: `${flexContent.picHeight}px`,
              }"
            />
          </div>
          <div class="text-box" v-if="flexContent.title">
            <p class="title">{{ flexContent.title }}</p>
            <p class="destobr">
              {{ flexContent.intro }}
            </p>
          </div>
          <div class="btn" v-if="flexContent.btnText">
            {{ flexContent.btnText }}
          </div>
          <div class="p-title" v-if="flexContent.pTitle">
            {{ flexContent.pTitle }}
          </div>
        </div>
      </div>
      <div class="content-btn" v-if="content.btnText">
        {{ content.btnText }}
      </div>
    </div> -->
    <contentN :contentList="contentList" :ewm="qrcode_url"/>
    <div class="content content-bg">
      <div class="content-top" :style="{paddingTop:!isMobile? '74px':'0.74rem'}">
        <div class="top-box-border">
          <div class="top-en">{{ class_module.en_title }}</div>
          <div class="top-cn">{{ class_module.cn_title }}</div>
        </div>
        <div class="top-title" v-if="!isMobile">
          {{ class_module.intro }}
        </div>
      </div>
      <div class="tabItem">
        <div
          :class="{ active: activeTabs == itemIndex }"
          @click="changeTabs(itemIndex, classItem.url)"
          v-for="(classItem, itemIndex) in class_module.pics"
          :key="itemIndex"
        >
          {{ classItem.title }}
        </div>
      </div>
      <div class="tab-content-img">
        <swiper-container
          class="mySwiperV"
          id="mySwiperV"
        >
          <swiper-slide
            class="my-swiper-slide swiper-no-swiping"
            v-for="(item, index) in  class_module.pics"
            :key="index"
            ><img v-lazy="item.url" alt="" /></swiper-slide
          >
        </swiper-container>
        <!-- <img v-lazy="class_module.acitvePic" :alt="activeTabs" /> -->
        <!-- <img v-lazy="https://pic.web.81zhijia.com/assets/1on1.png" alt="" v-else/> -->
      </div>
      <div class="content-btn" style="margin-top: 105px" @click="show" v-if="!isMobile">
        点击试听
      </div>
    </div>
    <div class="content">
      <div class="content-top">
        <div class="top-box-border">
          <div class="top-en">{{ live_module.en_title }}</div>
          <div class="top-cn">{{ live_module.cn_title }}</div>
        </div>
        <div class="top-title" v-if="!isMobile">
          {{ live_module.intro }}
        </div>
      </div>
      <div class="tab-content-img" :style="{paddingTop:!isMobile? '50px':'0.5rem'}">
        <img v-lazy="live_module.pic_url" alt="" />
      </div>
      <div class="content-btn" @click="show" v-if="!isMobile">
        {{ live_module.button_str }}
      </div>
    </div>
    <div class="content horor" style="padding-top: 50px">
      <div class="content-top">
        <div class="top-box-border">
          <div class="top-en">{{ honor_module.en_title }}</div>
          <div class="top-cn">{{ honor_module.cn_title }}</div>
        </div>
        <div class="top-title" v-if="!isMobile">
          {{ honor_module.intro }}
        </div>
      </div>
      <div class="swiper-box">
        <img
          v-lazy="'https://pic.web.81zhijia.com/assets/left.png'"
          class="iconImge"
          alt=""
          @click="turnLeft"
          v-if="!isMobile"
        />
        <swiper-container
          class="mySwiper"
          id="mySwiper"
          :slides-per-view="!isMobile? 4 : 1"
          :navigation="isMobile? true :false"
          space-between="10"
          loop="true"
          v-if="honor_img.length > 0"
        >
          <swiper-slide
            class="my-swiper-slide"
            v-for="(item, index) in honor_img"
            :key="index"
            @click="cleckHonor(item.photoName,item.photoUrl)"
            ><img v-lazy="item.photoUrl" alt="" />
            <p>{{ item.photoName }}</p></swiper-slide
          >
        </swiper-container>
        <img
          v-lazy="'https://pic.web.81zhijia.com/assets/right.png'"
          class="iconImge"
          alt=""
          @click="turnRight"
          v-if="!isMobile"
        />
      </div>
    </div>
    <el-dialog
      :title="dialogName"
      :visible.sync="dialogVisiblePic"
      top="10vh"
      width="500px"
      class="videoVi"
      lock-scroll
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="bg" style="background:transparent;">
        <i class="el-icon-close" @click="handleClose"></i>
        <img v-lazy="dialogPic" style="width:100%;" alt="">
      </div>
     
    </el-dialog>
    <kftc
      :ewm="qrcode_url"
      :centerDialogVisible="centerDialogVisible"
      @close="close"
      @show="show"
    />
    <footerN />
  </div>
</template>
<script>
import Top from "@/components/topTitle.vue";
import footerN from "@/components/footer.vue";
import contentN from "@/components/content.vue";
import kftc from "@/components/kftc.vue";
import {
  getConfig,
  getNewsList,
  videosList,
  photosList,
  getanners,
} from "@/api/api";
export default {
  name: "bktjS",
  data: () => {
    return {
      nameArr: ["首页", "报考军校"],
      qrcode_url:"",
      contentList: [],
      centerDialogVisible: false,
      dialogVisiblePic:false,
      dialogName:"",
      dialogPic:"",
      activeTabs: "0",
      noticActive: 0,
      class_module: {},
      live_module: {},
      honor_module: {},
      honor_img: [],
      noticList: [],
      bannerList: [],
      back_pic:"",
      news_id: "",
      newsList: [],
    };
  },
  components: {
    Top,
    footerN,
    contentN,
    kftc,
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  created() {
    this.getConfigFnc(5);
    this.getPic();
    this.getBanner();
    this.changeNotice(0, 15);
  },
  methods: {
    cleckHonor(name,pic){
      if(this.isMobile){
        return false
      }
      this.dialogName = name
      this.dialogPic = pic
      this.dialogVisiblePic = true
    },
    handleClose(){
      this.dialogVisiblePic = false
    },
    routerN(name, linkObj, query) {
      this.$store.dispatch("routerTO", { name, linkObj, query });
    },
    close() {
      this.centerDialogVisible = false;
    },
    show() {
      this.centerDialogVisible = true;
    },
    changeTabs(value, url) {
      this.activeTabs = value;
      // this.class_module.acitvePic = url;
      const swiperEl = document.getElementById("mySwiperV");
      swiperEl.swiper.slideTo(value, 1000, false);//切换到第一个slide，速度为1秒
    },
    turnLeft() {
      const swiperEl = document.getElementById("mySwiper");
      swiperEl.swiper.slidePrev();
    },
    turnRight() {
      const swiperEl = document.getElementById("mySwiper");
      swiperEl.swiper.slideNext();
    },
    changeNotice(value, id) {
      this.noticActive = value;
      this.news_id = id;
      this.getNewsListFnc();
    },
    getNewsListFnc() {
      getNewsList({
        typeId: this.news_id,
        page: 1,
        size: 6,
      }).then((res) => {
        // this.pageObj.total = res.data.newsList.records;
        this.newsList = res.data.newsList.rows;
      });
    },
    getBanner() {
      getanners({
        typeId: 2,
      }).then((res) => {
        this.bannerList = res.data;
      });
    },
    getConfigFnc(id) {
      getConfig({
        configId: id,
      }).then((res) => {
        this.qrcode_url = res.data.qrcode_url
        this.noticList = [res.data.news_module1, res.data.news_module2];
        res.data.all_module.cn_title = res.data.all_module.reason_title;
        this.back_pic = res.data.back_pic
        this.contentList[0] = {
          ...res.data.all_module,
          btnText: "了解更多优势",
        };
        res.data.book_module.pics.forEach((item) => {
          item.picWidth = "346";
          item.picHeight = "281";
          item.btnText = "查看详情 点击获取";
        });
        this.contentList[1] = {
          ...res.data.book_module,
        };
        res.data.video_module.pics.forEach((item) => {
          item.picWidth = "346";
          item.picHeight = "281";
          item.pTitle = item.title;
          delete item.title;
        });
        this.contentList[2] = {
          ...res.data.video_module,
          btnText: "立即了解",
        };
        this.contentList = JSON.parse(JSON.stringify(this.contentList));
        this.class_module = res.data.class_module;
        this.class_module.acitvePic = this.class_module.pics[0].url;
        this.live_module = res.data.live_module;
        this.honor_module = res.data.honor_module;
      });
    },
    getPic() {
      photosList({ type: 1 }).then((res) => {
        this.honor_img = [...res.data];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .videoVi{
  background: transparent;
  .el-dialog{
    background: transparent;
    box-shadow:none;
  }
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
    position: relative;
    .img{
      display: block;
    }
    .el-icon-close{
      position: absolute;
      top:  -40px;
      right: -40px;
      font-size: 40px;
      color: #bebebe;
      cursor: pointer;
    }
    video{
      display: block;
    }
  }
}
::v-deep .el-dialog__header{
  text-align: left;
}
.lbt {
  width: 100%;
  height: auto;
}
.notic-box {
  padding: 0 15%;
  margin-top: 30px;
  display: flex;
  .notic-right {
    flex: 1;
    margin-left: 0px;
    overflow: hidden;

    padding-left: 40px;
    .notice-content {
      .notic-content-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba($color: #979797, $alpha: 0.17);
        cursor: pointer;
        .notic-text {
          max-width: 70%;
          display: flex;
          align-items: center;
          position: relative;
          .dian {
            display: block;
            width: 7px;
            height: 7px;
            background: #ff2600;
            border-radius: 50%;
            margin-right: 6px;
            position: absolute;
            left: -14px;
          }
          .text {
            display: block;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            line-height: 50px;
            color: #000000;
          }
        }
      }

      .notic-time {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #919191;
        line-height: 50px;
      }
    }
    .notic-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .notice-tabs {
        display: flex;
        p {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 20px;
          color: #323232;
          line-height: 28px;
          margin-right: 30px;
          cursor: pointer;
          &.notice-tabs-active {
            color: #ff2600;
          }
        }
      }
      .more {
        cursor: pointer;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #919191;
        line-height: 28px;
        .el-icon-d-arrow-right {
          font-size: 18px;
          color: #919191;
        }
      }
    }
  }
  .notic-left {
    .notic-new {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 20px;
      color: #323232;
      line-height: 28px;
      text-align: left;
    }
    img {
      margin-top: 16px;
      display: block;
      width: 529px;
      height: 330px;
    }
  }
}
.content {
  margin-top: 80px;
  &.horor {
    background: #f2f2f2;
    padding-bottom: 80px;
  }
  &.content-bg {
    background: url("../assets/bg.png") no-repeat;
    background-size: 100%;
  }
  .honor {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: 591px;
    background: #999;
  }
  .tabItem {
    display: flex;
    margin: 45px auto 45px;
    align-items: center;
    justify-content: center;
    div {
      margin-left: 20px;
      width: 164px;
      cursor: pointer;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #19191b;
      line-height: 50px;
      text-align: center;
      // color: white;
      border-radius: 17px;
      border: 1px solid #999;
      &.active {
        background: #bd1d00;
        color: white;
      }
    }
  }
  .tab-content-img {
    display: flex;
    justify-content: center;
    .mySwiperV{
      width: 1082px;
    } 
    img {
      display: block;
      width: 1082px;
      height: 597px;
    }
  }
  .tab-box {
    display: flex;
    align-items: center;
    background: #bd1d00;
    padding: 40px 12%;
    .tab-content {
      .tabItem-content {
        margin-left: 80px;
        img {
          display: block;
          width: 714px;
          height: 394px;
          margin: auto;
        }
        p {
          margin-top: 40px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #f6f6ff;
          line-height: 28px;
          text-align: justify;
        }
      }
    }
    .video-box {
      // padding: 20px 0;
      .video-turn {
        width: 50px;
        height: 30px;
        margin: auto;
        background: rgba($color: #000000, $alpha: 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        &.turn-up {
          margin-bottom: 20px;
        }
        &.turn-down {
          margin-top: 20px;
        }
        .el-icon-arrow-up {
          font-size: 26px;
          color: white;
        }
        .el-icon-arrow-down {
          font-size: 26px;

          color: white;
        }
      }
    }
    .mySwiper1 {
      width: 300px;
      height: 525px;
      &::part(bullet-active) {
        background-color: white;
      }
      &::part(button-next) {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000000, $alpha: 0.2);
        border-radius: 50%;
        padding: 15px;
        bottom: 27px;
        left: 113px;
        top: auto;
        transform: rotate(90deg);
        --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
      }
      &::part(button-prev) {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000000, $alpha: 0.2);
        border-radius: 50%;
        padding: 15px;
        left: 113px;
        top: 0;
        transform: rotate(90deg);
        --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
      }
      .my-swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
        &.swiper-slide-prev {
          transform: scale(0.8);
        }
        &.swiper-slide-next {
          transform: scale(0.8);
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .content-top {
    margin: 0 auto;
    text-align: center;
    // padding-top: 74px;
    .top-box-border {
      // border: 1px dashed #;
      .top-en {
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 24px;
        color: #c5c5c5;
        line-height: 1;
        text-align: center;
      }
      .top-cn {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 44px;
        color: #141414;
        line-height: 1;
        letter-spacing: 4px;
        margin-top: 10px;
      }
    }

    .top-title {
      margin-top: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 20px;
      color: #858585;
      line-height: 28px;
      letter-spacing: 1px;
      background: transparent;
    }
  }
  .content-btn {
    border-radius: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 28px;
    color: #ffffff;
    line-height: 40px;
    letter-spacing: 2px;
    width: 335px;
    height: 80px;
    background: #bd1d00;
    line-height: 80px;
    margin: auto;
    margin-top: 30px;
    cursor: pointer;
    
  }
  .content-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10%;
    flex-wrap: wrap;
    margin-top: 40px;
    .flex-box {
      width: 23%;
      box-shadow: 0px 3px 0px 1px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      .pic-box {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .p-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #252525;
        line-height: 25px;
        text-align: center;
        margin: 16px auto 55px;
      }
      .btn {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #f2f2f2;
        text-align: center;
        width: 241px;
        height: 55px;
        margin: 23px auto 63px;
        line-height: 55px;
        border-radius: 6px;
        background: #bd1d00;

        cursor: pointer;
      }
      .text-box {
        padding: 18px 25px;
        .title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #39394d;
          line-height: 25px;
          text-align: center;
        }
        .destobr {
          font-family: PingFangSC, PingFang SC;
          font-weight: 300;
          font-size: 12px;
          color: #000000;
          line-height: 17px;
          text-align: center;
        }
      }
    }
  }
}
.mySwiper3 {
  margin: 0;
  width: 530px;
  // height: 388px;
  &::part(bullet-active) {
    background-color: white;
  }
  &::part(button-next) {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color: #000000, $alpha: 0.2);
    border-radius: 50%;
    padding: 15px;
    right: 40px;
    --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
  }
  &::part(button-prev) {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color: #000000, $alpha: 0.2);
    border-radius: 50%;
    padding: 15px;
    left: 40px;
    --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
  }
  &::part(bullet) {
    width: 10px;
    height: 10px;
    background: #9c9c9c;
    opacity: 1;
  }
  &::part(bullet-active) {
    width: 10px;
    height: 10px;
   
  }
  .my-swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    // display: flex;
    // justify-content: center;
    // align-items: center;
    img {
      width: 100%;
      display: block;
      // height: 100%;
    }
    p {
      text-align: center;
      margin: 0;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      line-height: 25px;
    }
  }
}
::v-deep .swiper-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  .iconImge {
    width: 47px;
    height: 47px;
    margin: 0 10px;
    cursor: pointer;
  }

  .mySwiper {
    margin: 0;
    width: 1090px;
    // height: 388px;
    &::part(bullet-active) {
      background-color: white;
    }
    &::part(button-next) {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba($color: #000000, $alpha: 0.2);
      border-radius: 50%;
      padding: 15px;
      right: 120px;
      --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
    }
    &::part(button-prev) {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba($color: #000000, $alpha: 0.2);
      border-radius: 50%;
      padding: 15px;
      left: 120px;
      --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
    }
    .my-swiper-slide {
      text-align: center;
      font-size: 18px;
      // background: #fff;
      /* Center slide text vertically */
      // display: flex;
      // justify-content: center;
      // align-items: center;
      img {
        width: 100%;
        display: block;
        // height: 100%;
      }
      p {
        text-align: center;
        margin: 0;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #000000;
        line-height: 25px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .mySwiper3 {
    width: 100%;
    &::part(bullet-active) {
      background-color: white;
    }
    &::part(button-next) {
      width: 0.5rem;
      height: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba($color: #000000, $alpha: 0.2);
      border-radius: 50%;
      padding: 0.06rem;
      right: 0.2rem;
      --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
      cursor: none;
    }
    &::part(button-prev) {
      width: 0.1rem;
      height: 0.1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba($color: #000000, $alpha: 0.2);
      border-radius: 50%;
      padding: 0.06rem;
      left: 0.2rem;
      --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
      cursor: none;
    }
    .my-swiper-slide {
      text-align: center;
      font-size: 0.18rem;
      background: #fff;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      &.content-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        // flex-wrap: wrap;
        // margin-top: 40px;
        .flex-box {
          width: 100%;
          box-shadow: 0px 3px 0px 1px rgba(0, 0, 0, 0.1);
          border-radius: 5px;

          padding: 0 0.6rem;
          &.col-3 {
            width: 30%;
            margin-bottom: 0.2rem;
          }
          .pic-box {
            width: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .p-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 0.18rem;
            color: #252525;
            line-height: 0.25rem;
            text-align: center;
            margin: 0.16rem auto 0.55rem;
          }
          .btn {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #f2f2f2;
            text-align: center;
            width: 2.41rem;
            height: 0.55rem;
            margin: 0.23rem auto 0.63rem;
            line-height: 0.55rem;
            border-radius: 0.06rem;
            background: #bd1d00;

            cursor: pointer;
          }
          .text-box {
            padding: 0.18rem 0.25rem;
            .title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 0.18rem;
              color: #39394d;
              line-height: 0.25rem;
              text-align: center;
            }
            .destobr {
              font-family: PingFangSC, PingFang SC;
              font-weight: 300;
              font-size: 0.12rem;
              color: #000000;
              line-height: 0.17rem;
              text-align: center;
            }
          }
        }
      }
      // img {
      //   width: 100%;
      //   height: 100%;
      // }
    }
  }
  .notic-box {
    margin-top: 0rem;
    display: block;
    padding: 0;
    .notic-right {
      flex: 1;
      margin-left: 0px;
      padding-right: .2rem;
      overflow: hidden;
      margin-top: .28rem;
      
    border-radius: 5px;
border: 1px solid rgba(151,151,151,0.3);

      padding-left: .4rem;
      .notice-content {
        .notic-content-flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgba($color: #979797, $alpha: 0.17);
          .notic-text {
            max-width: 70%;
            display: flex;
            align-items: center;
            position: relative;
            .dian {
              display: block;
              width: .1rem;
              height: .1rem;
              background: #ff2600;
              border-radius: 50%;
              margin-right: 6px;
              position: absolute;
              left: -0.14rem;
            }
            .text {
              display: block;
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: .28rem;
              line-height: .7rem;
              color: #000000;
            }
          }
        }

        .notic-time {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: .26rem;
          color: #919191;
          line-height: .7rem;
        }
      }
      .notic-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .notice-tabs {
          display: flex;
          p {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: .24rem;
            color: #323232;
            line-height: .47rem;
            margin-right: .2rem;
            padding: 0 .3rem;
            cursor: pointer;
            background:rgba($color: #9B9B9B, $alpha: 0.15);
border-radius: 24px;
            border-radius: .28rem;
            &.notice-tabs-active {
              // color: #ff2600;
              background: #ff2600;
              color: white;
              
            }
          }
        }
        .more {
          cursor: pointer;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: .18rem;
          color: #919191;
          line-height: .28rem;
          .el-icon-d-arrow-right {
            font-size: .18rem;
            color: #919191;
          }
        }
      }
    }

    .notic-left {
      .notic-new {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 20px;
        color: #323232;
        line-height: 28px;
        text-align: left;
      }
      img {
        margin-top: 0;
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  .content {
  margin-top: .28rem;
  border-radius: 0.05rem;
    border: 1px solid rgba(151, 151, 151, 0.3);
    padding: 0.42rem 0 0;

  &.horor {
    background: #f2f2f2;
    padding-bottom: .8rem;
  }
  &.content-bg {
    background:white;
    background-size: 100%;
    .content-top{
      
    padding-top: 0 !important;
    }
  }
  .honor {
    margin-top: .1rem;
    display: block;
    width: 100%;
    height: 5.91rem;
    background: #999;
  }
  .tabItem {
    display: flex;
    margin: 0.4rem auto 0.4rem;
    align-items: center;
    justify-content: center;
    div {
      margin-left: .2rem;
      cursor: pointer;
      width: auto;
      padding: 0 .2rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: .2rem;
      color: #19191b;
      line-height: .5rem;
      text-align: center;
      // color: white;
      border-radius: .17rem;
      border: 1px solid #999;
      &.active {
        background: #bd1d00;
        color: white;
      }
    }
  }
  .tab-content-img {
    display: flex;
    justify-content: center;
    
    .mySwiperV{
      width: 100%;
    } 
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .tab-box {
    display: flex;
    align-items: center;
    background: #bd1d00;
    padding: .4rem 0%;
    .tab-content {
      .tabItem-content {
        margin-left: .8rem;
        img {
          display: block;
          width: 100%;
      height: auto;
          margin: auto;
        }
        p {
          margin-top: .4rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: .16rem;
          color: #f6f6ff;
          line-height: .28rem;
          text-align: justify;
        }
      }
    }
   
    .mySwiper1 {
      width: 300px;
      height: 525px;
      &::part(bullet-active) {
        background-color: white;
      }
      &::part(button-next) {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000000, $alpha: 0.2);
        border-radius: 50%;
        padding: 15px;
        bottom: 27px;
        left: 113px;
        top: auto;
        transform: rotate(90deg);
        --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
        cursor: none;
      }
      &::part(button-prev) {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000000, $alpha: 0.2);
        border-radius: 50%;
        padding: 15px;
        left: 113px;
        top: 0;
        transform: rotate(90deg);
        --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
        cursor: none;
      }
      .my-swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
        &.swiper-slide-prev {
          transform: scale(0.8);
        }
        &.swiper-slide-next {
          transform: scale(0.8);
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .content-top {
    margin: 0 auto;
    text-align: center;
    // padding-top: 74px;
    .top-box-border {
      // border: 1px dashed #;
      .top-en {
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: .24rem;
        color: #c5c5c5;
        line-height: 1;
        text-align: center;
      }
      .top-cn {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: .44rem;
        color: #141414;
        line-height: 1;
        letter-spacing: .04rem;
        margin-top: .1rem;
      }
    }

    .top-title {
      margin-top: .16rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: .2rem;
      color: #858585;
      line-height: .28rem;
      letter-spacing: 1px;
      background: transparent;
    }
  }
  .content-btn {
    border-radius: .2rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 28px;
    color: #ffffff;
    line-height: .4rem;
    letter-spacing: .02rem;
    width: 3.35rem;
    height: .8rem;
    background: #bd1d00;
    line-height: .8rem;
    margin: auto;
    margin-top: .3rem;
    cursor: pointer;
  }
 
}
::v-deep .swiper-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .28rem;
  .iconImge {
    width: .47rem;
    height: .47rem;
    margin: 0 10px;
    cursor: pointer;
  }

  .mySwiper {
    width: 100%;
    
    box-sizing: border-box;
      &::part(bullet-active) {
        background-color: white;
      }
      &::part(button-next) {
        width: .2rem;
        height: .7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000000, $alpha: 0);
        border-radius: 0%;
        padding: .06rem;
        top: calc(50% - 20px);
        right: 0.2rem;
        --swiper-navigation-color: #a69d9d; /* 单独设置按钮颜色 */
        cursor: none;
      }
      &::part(button-prev) {
        width: .2rem;
        height: .7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000000, $alpha: 0);
        border-radius: 0%;
        padding: .06rem;
        top: calc(50% - 20px);
        left: 0.2rem;
        --swiper-navigation-color: #a69d9d; /* 单独设置按钮颜色 */
        cursor: none;
      }
      .my-swiper-slide {
        text-align: center;
        font-size: .18rem;
        background: transparent;
        /* Center slide text vertically */
        // display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 .6rem;
        box-sizing: border-box;
        &.content-flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          // flex-wrap: wrap;
          // margin-top: 40px;
          .flex-box {
            width: 100%;
            box-shadow: 0px 3px 0px 1px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            
        padding: 0 .6rem;
            &.col-3 {
              // width: 30%;
              width: auto;
              margin-bottom: .2rem;
            }
            .pic-box {
              width: 100%;

              img {
                width: 100%;
              }
            }
            .p-title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 600;
              font-size: .18rem;
              color: #252525;
              line-height: .25rem;
              text-align: center;
              margin: .16rem auto .55rem;
            }
            .btn {
              font-family: PingFangSC, PingFang SC;
              font-weight: 600;
              font-size: 14px;
              color: #f2f2f2;
              text-align: center;
              width: 2.41rem;
              height: .55rem;
              margin: .23rem auto .63rem;
              line-height: .55rem;
              border-radius: .06rem;
              background: #bd1d00;
              padding: .06rem .12rem;

              cursor: pointer;
            }
            .text-box {
              padding: .18rem .25rem;
              .title {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: .18rem;
                color: #39394d;
                line-height: .25rem;
                text-align: center;
              }
              .destobr {
                font-family: PingFangSC, PingFang SC;
                font-weight: 300;
                font-size: .12rem;
                color: #000000;
                line-height: .17rem;
                text-align: center;
              }
            }
          }
        }
        // img {
        //   width: 100%;
        //   height: 100%;
        // }
      }
    }
}
}
</style>