import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    searchType: 0,
    isMobile: false
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    routerTO({ commit }, config) {
     if(String(config.linkObj && config.linkObj.jump_type) == 0){
      return false
     }
      if (config.name) {
        router.push({
          name: config.name,
          query: config.query
        })
      } else {
        let name = ""
        let obj = {

        }
        switch (String(config.linkObj.jump_type)) {
          case "1":
            name = "news_list"
            obj.jump_url = config.linkObj.jump_url
            break;
          case "2":
            name = "xwlb"
            obj.id = config.linkObj.jump_url
            break;
          case "3":
            name = "zwT"
            obj.id = config.linkObj.jump_url
            break;
          case "4":
            if (config.linkObj.jump_url == 5) {
              name = "exam_home"
            } else if (config.linkObj.jump_url == 6) {
              name = "civil_home"
            } else if (config.linkObj.jump_url == 10) {
              name = "job_home"
            } else if (config.linkObj.jump_url == 7) {
              name = "skill_home"
            } else if (config.linkObj.jump_url == 8) {
              name = "edu_home"
            } else if (config.linkObj.jump_url == 9) {
              name = "nat_home"
            }
            break;
          case "5":
            name = "gglb"
            obj.id = config.linkObj.jump_url
            break;
          case "6":
            name = "zwT"
            obj.type = 'gg'
            obj.id = config.linkObj.jump_url
            break;
          case "7":
              window.open(config.linkObj.jump_url)
              return false
              break;
          default:
            break;
        }
        router.push({
          name: name,
          query: {
            ...config.query,
            ...obj
          }
        })
      }
    }
  },
  modules: {
  }
})
