import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function getAchillesToken() {
  return localStorage.getItem('achilles-token')
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: 7 })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function autoSize() {
  //设计稿的宽度
  var psdWidth = 750;

  //预设字体值
  var preFontSize = 100;

  //当前屏幕宽度
  var curScreenWidth = document.documentElement.clientWidth;
  //当前屏幕对应的html字体值
  var curFontSize = (curScreenWidth * preFontSize) / psdWidth;

  //设备宽度在750px 
  if (curScreenWidth > 750) {
    curScreenWidth = 750;
  }else{
    //把计算下来的当前屏幕html字体值 赋值给html元素 documentElement属性会获取到html根元素 然后为之设置字体值
    document.documentElement.style.fontSize = curFontSize + "px";
  }
  
  
}
