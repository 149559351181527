import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
// import './assets/styles/element-variables.scss'
import 'element-ui/lib/theme-chalk/index.css'
import { register } from 'swiper/element/bundle';
import './assets/styles/swiper-bundle.min.css'
import BaiduMap from 'vue-baidu-map'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import VueLazyload from 'vue-lazyload';
// register Swiper custom elements
register();

Vue.prototype.store = store
Vue.config.productionTip = false
Vue.use(Element, {
  size:'medium' // set element-ui default size
})
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '2KtQDEvp76nxZSNAcFAfjxuPaXxeR50f'
})

Vue.use(preview)

const loadimage = require('./assets/imgLoading.png')
const imgError = require('./assets/imgError.png')
Vue.use(VueLazyload, {
  // 设置懒加载时的占位图
  loading: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1036' height='340'></svg>",
  // 设置图片加载失败时的占位图
  error: '',
  attempt:2,
  adapter:{
    loading(e){
      e.el.classList.add("load-img")
      let height = e.naturalHeight
      if(e.naturalHeight > 300){
        height = 300
      }
      e.el.style.minHeight = e.naturalHeight + 'px'
    },
    loaded(e){
      // console.log(e)
      e.el.classList.remove("load-img")
      e.el.style.minHeight = '0'
    }
  },
  observer:true,
  observerOptions:{
    root: null,
  rootMargin: '-50px',
  threshold: 0.1 // 出现50%
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
